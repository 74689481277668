import React, { useEffect, useState } from "react";
import OrderHistoryCardItem from "../components/OrderHistoryCardItem";
import capturePreviouslyAuthorizedAmount from "../payment/PaymentTransactions/capture-previously-authorized-amount";
import voidTransaction from "../payment/PaymentTransactions/void-transaction";
import ProfileModal from "./ProfileModal";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { database, firestore } from "../config/firebase";

import OrderConfirmModal from "./OrderConfirmModal";
import OrderRejectModal from "./OrderRejectModal";
import OrderDenineModal from "./OrderDenineModal";

function OrderConfirm(props) {
  const [userData, setuserData] = useState("");
  const [orderUserData, setOrderUserData] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [availableBal, setAvailableBal] = useState(0);
  const [orderConfirmModalShow, setOrderConfirmModalShow] = useState(false);
  const [orderRejectModalShow, setOrderRejectModalShow] = useState(false);
  const [orderDenineModalShow, setOrderDenineModalShow] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const userId = await localStorage.getItem("userUid");
      setUserId(userId);
      const orderUser = await firestore
        .collection("User")
        .doc(props.userId)
        .get();
      const fetchOrderUserData = {
        firstName: orderUser.data()?.fristName,
        lastName: orderUser.data()?.lastName,
        email: orderUser.data()?.email,
      };
      if (fetchOrderUserData.lastName) {
        let lastName = fetchOrderUserData.lastName.substring(0, 1);
        setLastName(lastName);
      }
      setOrderUserData(fetchOrderUserData);

      const userData = await firestore.collection("User").doc(userId).get();
      const fetchUserData = {
        firstName: userData.data().fristName,
        lastName: userData.data().lastName,
        email: userData.data().email,
        phoneNum: userData.data().phoneNum,
        city: userData.data().city,
        storeStreetAdress: userData.data().storeStreetAdress,
        zipCode: userData.data().zipCode,
        availableBal: userData.data().availableBal,
        storeName: userData.data().storeName,
        storePhoneNum: userData.data().storePhoneNum,
      };
      setuserData(fetchUserData);
      setAvailableBal(fetchUserData.availableBal);
    };

    fetchData();
  }, []);

  const result = async (res) => {
    setLoading(false);
    // let resultCode = res.messages.resultCode
    let resultCode = "Ok";

    // saving order data into firebase
    if (resultCode == "Ok") {
      uploadConfirmData(orderUserData);
      let today = dayjs(new Date()).format("hh:mm A MM/DD/YYYY");
      let shopValue = "";
      if (userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1") {
        shopValue = availableBal + props.totalPrice * 0.85;
      } else {
        shopValue = availableBal + props.totalPrice * 0.85;
      }
      // if (props.usertype === 'dispensary') {
      const newOrderListKey = firestore.collection("OrderLists").doc().id;
      const storeAddress = `${userData.storeStreetAdress}, ${userData.city}, GA, ${userData.zipCode}`;
      const deliveryAddres = `${props.dropStreet}, ${props.dropCity}, GA, ${props.dropZip}`;
      firestore
        .collection("OrderLists")
        .doc(newOrderListKey)
        .set({
          orderListId: newOrderListKey,
          storeAddress: storeAddress,
          storeFirstName: userData.firstName,
          storeLastName: userData.lastName,
          storeEmail: userData.email,
          storeOwnerNum: userData.phoneNum,
          storePhoneNum: userData.storePhoneNum,
          storeName: userData.storeName,
          storeZip: userData.zipCode,
          storeStreet: userData.storeStreetAdress,
          deliveryAddres: deliveryAddres,
          consumerFirstName: props.firstName,
          consumerLastName: props.lastName,
          consumerStreet: props.dropStreet,
          orderItem: props.orderItem,
          consumerZip: props.dropZip,
          consumerLineAddress: props?.dropLineAddress || "",
          consumerEmail: props.email,
          consumerPhoneNum: props.phoneNum,
          specialIns: props.specialIns,
          placeDate: props.placeDate,
          confirmDate: today,
          storePayout: props.totalPrice * 0.85,
        });

      firestore
        .collection("OrderItems")
        .where("storeId", "==", userId)
        .where("userId", "==", props.userId)
        .where("orderId", "==", props.orderId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              dropStatus: true,
              confirmStatus: true,
              dropDate: today,
              pendingStatus: "Captured",
            });
          });
        });
      firestore
        .collection("User")
        .doc(userId)
        .update({ availableBal: shopValue });
      // firestore
      //   .collection("User")
      //   .doc(props.userId)
      //   .update({ promoFlag: false });
      for (let i = 0; i < props.orderItem.length; i++) {
        const element = props.orderItem[i];
        var itemCollectionRef = firestore.collection("Items");
        var itemDocRef = itemCollectionRef.doc(element.itemId);
        const itemQueryData = await itemDocRef.get();
        const data = itemQueryData.data();
        if (
          element.variantRealArray === undefined ||
          element.variantRealArray === null ||
          element.variantRealArray.length === 0
        ) {
          data.itemNum1 -= element.num;
          await itemDocRef.set(data);
        } else {
          data.variantItemValues[element.variantIndex].count -= element.num;
          await itemDocRef.set(data);
        }
      }
      toast(
        `Thank you, CannaGo and ${orderUserData.firstName} ${lastName}. Have been notified. Please keep ${orderUserData.firstName} ${orderUserData.lastName}. in a safe place until a driver arrives.`
      );
      // setOrderRejectModalShow(true);
      // } else {
      //   history.push('driverorderstatus')
      // }
    } else {
      toast("Transaction Failed. Please try again.");
    }
  };

  const pickup = () => {
    setOrderConfirmModalShow(true);
  };

  const orderConfirmHandler = () => {
    let data = { refTransID: props.refTransID, refId: props.refId };
    capturePreviouslyAuthorizedAmount.capturePreviouslyAuthorizedAmount(
      result,
      data
    );
  };

  const uploadConfirmData = async (data) => {
    firestore.collection("ConfirmedOrder").add({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      storeName: userData.storeName,
      confirmedStatus: true,
      orderUserId: props.userId,
      orderId: props.orderId,
    });
  };

  const voidResult = (res) => {
    setLoading(false);
    let resultCode = res.messages.resultCode;
    // saving order data into firebase
    if (resultCode == "Ok") {
      let userId = localStorage.getItem("userUid");
      uploadReportData(orderUserData);
      // alert('deny')
      firestore
        .collection("OrderItems")
        .where("storeId", "==", userId)
        .where("userId", "==", props.userId)
        .where("orderId", "==", props.orderId)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            doc.ref.update({
              placeStatus: false,
              pendingStatus: "Voided",
            });
          });
        });
      toast(
        `Thank you, CannaGo and ${orderUserData.firstName} ${lastName}. have been notified of order cancelation.`
      );
    } else {
      toast("Transaction Failed. Please try again.");
    }
  };

  const deny = () => {
    setOrderDenineModalShow(true);
  };

  const orderDenineHandler = () => {
    let data = { refTransID: props.refTransID, refId: props.refId };
    voidTransaction.voidTransaction(voidResult, data);
  };

  const uploadReportData = (data) => {
    firestore.collection("RejectedOrder").doc(props.userId).set({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      storeName: userData.storeName,
      rejectedStatus: true,
    });
  };

  const [modalShow, setModalShow] = useState(false);
  const modalHandler = () => {
    setModalShow(true);
  };
  return (
    <div className="order-confirm">
      <OrderConfirmModal
        show={orderConfirmModalShow}
        userData={orderUserData}
        lastName={lastName}
        onConfirm={() => orderConfirmHandler()}
        onHide={() => setOrderConfirmModalShow(false)}
      />
      <OrderDenineModal
        show={orderDenineModalShow}
        userData={orderUserData}
        lastName={lastName}
        onConfirm={() => orderDenineHandler()}
        onHide={() => setOrderDenineModalShow(false)}
      />
      <OrderRejectModal
        show={orderRejectModalShow}
        userData={orderUserData}
        lastName={lastName}
        onHide={() => setOrderRejectModalShow(false)}
      />
      <div className="order-confirm-wrapper">
        <div className="order-item-area">
          {props.orderItem &&
            props.orderItem.map((el) => (
              <OrderHistoryCardItem
                key={el.id}
                {...el}
                img={props.img1}
                price={props.price1}
                qty={props.qty1}
                confirm
              />
            ))}
        </div>
        <div className="order-desc-area">
          <div className="row-array space-between">
            <h2 className="mb50">Order Placed {props.placeDate}</h2>
            {/* {
              !props.paid &&
              <div>
                <img src={msg} alt="msg" />
                <img src={phone} alt="phone" />
              </div>
            } */}
          </div>
          <div className="full-width">
            <h2>
              {orderUserData.firstName} {lastName}. order #{props.orderId}
            </h2>
            {props.usertype === "dispensary" ? (
              <>
                <div className="row-array space-between">
                  <h3>Delivery Address:</h3>
                  <h3>
                    {props.dropStreet}, {props.dropCity}, GA {props.dropZip}
                  </h3>
                </div>
                <div className="row-array space-between">
                  <h3>Product Total</h3>
                  <h3>${props.totalPrice}</h3>
                </div>
                <div className="row-array space-between">
                  <h3>CannaGo's Service Fee</h3>
                  <h3>
                    -$
                    {userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1"
                      ? (props.totalPrice * 0.15).toFixed(2)
                      : (props.totalPrice * 0.15).toFixed(2)}
                  </h3>
                </div>
              </>
            ) : props.paid ? (
              <>
                <div className="row-array space-between">
                  <h3>Delivery Address:</h3>
                  <h3>
                    {props.dropStreet}, {props.dropCity}, GA {props.dropZip}
                  </h3>
                </div>
                <div className="row-array space-between">
                  <h3>Product Total</h3>
                  <h3>${props.totalPrice}</h3>
                </div>
                <div className="row-array space-between">
                  <h3>CannaGo's Service Fee</h3>
                  <h3>
                    -$
                    {userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1"
                      ? (props.totalPrice * 0.15).toFixed(2)
                      : (props.totalPrice * 0.15).toFixed(2)}
                  </h3>
                </div>
              </>
            ) : (
              <>
                <div className="row-array space-between">
                  <h3>Delivery Address:</h3>
                  <h3>
                    {props.dropStreet}, {props.dropCity}, GA {props.dropZip}
                  </h3>
                </div>
                <div className="row-array space-between">
                  <h3>Product Total</h3>
                  <h3>${props.totalPrice}</h3>
                </div>
                <div className="row-array space-between">
                  <h3>CannaGo's Service Fee</h3>
                  <h3>
                    -$
                    {userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1"
                      ? (props.totalPrice * 0.15).toFixed(2)
                      : (props.totalPrice * 0.15).toFixed(2)}
                  </h3>
                </div>
              </>
            )}
          </div>
          <div className="row-array space-between mt90 full-width mb90">
            <h2>Your payout:</h2>
            {props.usertype === "dispensary" ? (
              <h1 className="payout-price">
                $
                {userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1"
                  ? (props.totalPrice * 0.85).toFixed(2)
                  : (props.totalPrice * 0.85).toFixed(2)}
              </h1>
            ) : (
              <h1 className="payout-price">
                $
                {userId == "0SDcLRbvhXd9WBLZJaGeTSViHFr1"
                  ? (props.totalPrice * 0.85).toFixed(2)
                  : (props.totalPrice * 0.85).toFixed(2)}
              </h1>
            )}
          </div>
          <ProfileModal
            userData={userData}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
          {props.paid ? (
            <>
              <button onClick={modalHandler} className="report mb50">
                Report an Issue
              </button>
              <h2>Order Completed {props.dropDate}</h2>
            </>
          ) : (
            <div className="space-between">
              <button onClick={pickup} className="btn-pickup">
                {props.usertype === "dispensary"
                  ? "Order Ready For PickUp"
                  : "Drop-off Order"}
              </button>
              <button onClick={deny} className="modal-cancel">
                {props.usertype === "dispensary"
                  ? "Don't have Item"
                  : "Find Another Driver"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OrderConfirm;
