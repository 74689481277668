import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import ProfileModal from './ProfileModal'
import Profile from './Profile'
import ProfileCard from './ProfileCard'
import Switch from "react-switch";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import avatar from '../images/avatar.png'
import User from '../images/user-green.svg'
import Hist from '../images/history.svg'
import BG from '../images/profile-bg.svg'
import licenseImg from '../images/licenseImg.svg'
// Firebase Integration module
import { database, storage } from '../config/firebase'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { injectStyle } from "react-toastify/dist/inject-style";

if (typeof window !== "undefined") {
    injectStyle();
}

const ProfileSettings = (props) => {
    let history = useHistory();
    const [modalShow, setModalShow] = useState(false);
    const [promoMessage, setPromoMessage] = useState("");
    const [promoCode, setPromoCode] = useState("");
    const [checked, setChecked] = useState(false)
    const [userType, setUserType] = useState(props.role)
    const [userUid, setUserUid] = useState('')
    const [userData, setUserData] = useState({})
    var userDatas = {}

    // const [url] = useState(localStorage.getItem('url'))
    const [loading, setLoading] = useState(false);
    const [url, setUrl] = useState(avatar);
    useEffect(() => {
        let userId = localStorage.getItem('userUid')
        setUserType(localStorage.getItem('usertype'))
        setUserUid(localStorage.getItem('userUid'))

        database
            .ref('user/' + userId + '/consumer')
            .on("value", (snapshot) => {
                let user_data = {
                    email: snapshot.val().email,
                    firstName: snapshot.val().fristName,
                    lastName: snapshot.val().lastName,
                    password: snapshot.val().password,
                    phoneNum: snapshot.val().phoneNum,
                    profileimage: snapshot.val().profileimage,
                    userType: snapshot.val().userType,
                    zipCode: snapshot.val().zipCode,
                    age: snapshot.val().age,
                    gender: snapshot.val().gender,
                    birthday: snapshot.val().birthday,
                    promoCode: snapshot.val().promoCode,
                    referralValue: snapshot.val().referralValue,
                    loyalCodeNum: snapshot.val().loyalCodeNum,
                    loyalCode: snapshot.val().loyalCode,
                };
                setUserData(user_data)
                userDatas = user_data
                let promoMessage1 = "Hey! I’ve been using CannaGo to order all my CBD, Hemp, Delta 8, and Vape products. Try using my referral code on your first order and we’ll both get $5. Referral Code: " + user_data.promoCode + "Check them out at https://www.trycannago.com/"
                setPromoMessage(promoMessage1)
            })
    }, [])
    useEffect(() => {
        setUrl(localStorage.getItem('url'))
    }, [localStorage.getItem('url')])
    const logout = () => {
        localStorage.setItem('loggedIn', false);
        localStorage.removeItem('usertype')
        localStorage.removeItem('userUid')
        localStorage.removeItem('username')
        localStorage.removeItem('dropAddress')
        localStorage.removeItem('itemImage')
        localStorage.removeItem('openStatus')
        localStorage.removeItem('disCity')
        localStorage.removeItem('disZipe')
        localStorage.removeItem('city')
        localStorage.removeItem('zip')
        localStorage.removeItem('storeName')
        localStorage.removeItem('disStreet')
        localStorage.removeItem('age')
        localStorage.removeItem('url')
        localStorage.removeItem('profileImage')
        localStorage.removeItem('password')
        localStorage.removeItem('email')
        // localStorage.removeItem('ageFlag')
        history.push("/");
    }
    const modalHandler = () => {
        // setModalShow(true)
        history.push("/contactus")
    }
    const profile = () => {
        userType === 'consumer' ?
            history.push("/profileinfo")
            :
            history.push("/driverprofileinfo")
    }
    const orderhistory = () => {
        userType === 'consumer' ?
            history.push('/orderhistory')
            :
            console.log('driver history');
    }
    const iconStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#878787",
        paddingRight: 2
    }

    const plusHandler = () => {
        !loading &&
            document.getElementById("input").click()
    }

    const imageHandler = async e => {
        // image preview on avatar area
        // const reader = new FileReader();
        // reader.onload = () => {
        //   if (reader.readyState === 2) {
        //     setProfileImg(reader.result)
        //   }
        // };
        // reader.onerror = function (e) {
        //   alert('Image picker error');
        // }
        // if (e.target.files[0]) {
        //   reader.readAsDataURL(e.target.files[0]);
        //   setImage(e.target.files[0])
        // }

        // Firebase image upload
        if (e.target.files[0]) {
            var newItemKey = database.ref().child('user').push().key;
            var _name = newItemKey + 'img.jpg';
            setLoading(true)
            const uploadTask = storage.ref(`ProfileImages/${_name}`).put(e.target.files[0]);
            uploadTask.on(
                "state_changed",
                snapshot => {
                    // const progress = Math.round(
                    //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    // );
                    // setProgress(progress);
                },
                error => {
                },
                () => {
                    storage
                        .ref("ProfileImages")
                        .child(_name)
                        .getDownloadURL()
                        .then(url => {
                            setUrl(url);
                            localStorage.setItem('url', url)
                            database.ref('user/' + userUid + `/${userType}`).update({
                                profileimage: url,
                            })
                            setLoading(false)
                        });
                }
            );
        }
    };

    const generatePromoCode = () => {
        // var tempUserData
        // if (userData.email !== null && userData.email !== undefined && userData.email !== "") {
        //     tempUserData = userData.email
        // } else {
        //     tempUserData = userDatas.email
        // }
        // let userId = localStorage.getItem('userUid')
        // let randomNum = shuffle("0123456789".split('')).join('').substring(0, 4);
        // let userName = localStorage.getItem('username')
        // let str = userName.replace(/\s+/, '-');
        // let promoCode = str + "-" + randomNum
        // let promoMessage1 = "Hey! I’ve been using CannaGo to order all my CBD products. Try using my referral code on your first order and we’ll both get $5. Referral Code: " +promoCode

        // setPromoMessage(promoMessage1)
        // setPromoCode(promoCode)
        // var newItemKey = database.ref().child('PromoCodes').push().key;
        // database
        //     .ref('PromoCodes/' + newItemKey)
        //     .update({
        //         promoCode: promoCode,
        //         userId: userId,
        //         email: tempUserData,
        //         promoCodeStatus: false,
        //         promoId: newItemKey
        //     });
    }
    // function shuffle(o) {
    //     for (var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    //     return o;
    // }

    return (
        <div className="profile-settings">
            <ProfileModal show={modalShow} onHide={() => setModalShow(false)} />
            <div className="profile-cont">
                {userType === 'driver' && <h1 className="driver-profile-title">Profile</h1>}
                {userData.loyalCode !== "" ?
                    <div className="loyaltyCodeArea">
                        <p className="loyaltyCode">You only have {userData.loyalCodeNum == 0 ? "three" : userData.loyalCodeNum == 1 ? "two" : "one"} more orders from {userData.loyalCode} to get $10 off</p>
                    </div> : ""
                }
                <p className="intiveValue">Available Referral Balance<span className="promocodeArea">${userData.referralValue}</span></p>
                <div className="profile-avatar">
                    <Profile src={url} onClick={plusHandler} onChange={imageHandler} loading={loading} />
                    {
                        userType === 'consumer' ?
                            <div className="invite">
                                <p>{localStorage.getItem('username')}.</p>
                                {/* <button className="intiveBtn" onClick={()=>{generatePromoCode()}}>Invite a Friend, Get$5 </button>
                                <p className="intiveTxt">When they complete their first order using your code you both get$5</p> */}
                                <p className="intiveTxt">Your Referral Code is <span className="promocodeArea">{userData.promoCode}</span></p>
                            </div>
                            :
                            <div className="driver-avatar-desc">
                                <div className="driver-avatar-info">
                                    <h3>Larry W, 33</h3>
                                    <h1>$275.77</h1>
                                    <h5>Available Balance</h5>
                                </div>
                                <div className="switchArea-driver">
                                    <Switch
                                        onChange={() => setChecked(!checked)}
                                        checked={checked}
                                        width={100}
                                        height={40}
                                        uncheckedIcon={<div style={iconStyle}>Online</div>}
                                        checkedIcon={<div style={iconStyle}>Offline</div>}
                                        offColor="#fff"
                                        onColor="#fff"
                                        offHandleColor="#61D273"
                                        onHandleColor="#878787"
                                        handleDiameter={30}
                                        activeBoxShadow="0 0 2px 3px #3bf"
                                    />
                                </div>
                            </div>
                    }
                </div>
                <CopyToClipboard text={promoMessage}
                    onCopy={() => { toast('Referral Code has been copied.') }}>
                    <ProfileCard text={userType === 'driver' ? "Drive Information" : "Refer a friend and you both get $5"} src={userType === 'driver' ? licenseImg : User} />
                </CopyToClipboard>
                <ProfileCard onClick={profile} text={userType === 'driver' ? "Drive Information" : "Profile Information"} src={userType === 'driver' ? licenseImg : User} />
                <ProfileCard onClick={orderhistory} text={userType === 'driver' ? "Driver History" : "Order History"} src={Hist} />
                <ProfileCard onClick={modalHandler} text="Contact Support" src={User} />
                <ProfileCard onClick={logout} logout={true} text="Log Out" src={User} />
            </div>
            <div className="profile-bg">
                {userType === 'consumer' && <img src={BG} />}
            </div>
            <ToastContainer />
        </div>
    )
}
export default ProfileSettings;