import React, { useState, useEffect } from "react";
import App, { firestore } from "../config/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import Spinner from "react-bootstrap/Spinner";
import DispensaryNavbar from "../components/DispensaryNavbar";
import checkedImage from "../images/checkedImage.png";
import uncheckedImage from "../images/uncheckedImage.png";

if (typeof window !== "undefined") {
  injectStyle();
}

var tempUserList = [];

function AccountSharingScreen() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userUid, setUserUid] = useState("");
  const [uploading, setUploading] = useState(false);
  const [role, setRole] = useState("");
  const [userList, setUserList] = useState([]);

  let userId = localStorage.getItem("userUid");
  useEffect(() => {
    setUserUid(localStorage.getItem("userUid"));
    firestore
      .collection("Employee")
      .doc(userId)
      .onSnapshot((documentSnapshot) => {
        tempUserList = [];
        let fetchEmployeeData = documentSnapshot.data();
        if (fetchEmployeeData != undefined) {
          fetchEmployeeData.employeeList.forEach((employee) => {
            if (employee.isDelected !== true) {
              tempUserList.push(employee);
            }
          });
        }
        setUserList(tempUserList);
      });
  }, []);

  const AddHandler = () => {
    if (
      firstName == "" ||
      lastName == "" ||
      email == "" ||
      password == "" ||
      role == ""
    ) {
      toast("Please input all fields.");
    } else {
      setUploading(true);
      try {
        App.auth()
          .createUserWithEmailAndPassword(email, password)
          .then((res) => {
            tempUserList.push({
              email: email,
              fristName: firstName,
              lastName: lastName,
              role: role,
              storeId: userUid,
              userId: res.user.uid,
              isDelected: false,
            });
            if (tempUserList.length == 1) {
              firestore.collection("Employee").doc(userId).set({
                employeeList: tempUserList,
              });
            } else
              firestore.collection("Eemployee").doc(userId).update({
                employeeList: tempUserList,
              });
            setUploading(false);
          })
          .catch((error) => {
            setUploading(false);
            var errorCode = error.code;
            var errorMessage = error.message;
            if (errorCode === "auth/email-already-in-use") {
              tempUserList.forEach((employee) => {
                if (employee.email == email) {
                  if (employee.isDelected == false) {
                    toast.warning(errorMessage);
                  }
                } else {
                }
              });
              return false;
            } else {
              alert(error);
            }
          });
      } catch (error) {
        alert("Network error", error);
      }
    }
  };

  const removeAccess = (id) => {
    tempUserList[id].isDelected = true;
    setUserList(tempUserList);
    firestore.collection("Employee").doc(userId).update({
      employeeList: tempUserList,
    });
  };

  return (
    <div>
      <DispensaryNavbar />
      <div className="shareAccountContent">
        <div className="priceArea mb30 paymentvalue">
          <h1 className="mt10" style={{ fontSize: 30 }}>
            Grant User Access
          </h1>
          <h5
            className="mt50 mb30 greyTxt"
            style={{ fontSize: 18, textAlign: "center" }}
          >
            Insert the persons information you would like grant access
          </h5>
          <div className="accountNameField">
            <input
              className="shareAccountInput"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="First Name"
            />
            <input
              className="shareAccountInput"
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Last Name"
            />
          </div>
          <div className="authPart">
            <input
              className="shareAccountInput1"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email"
            />
            <input
              className="shareAccountInput1"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              placeholder="Password"
              type="password"
            />
          </div>

          <h5 className="mt30 greyTxt" style={{ fontSize: 18 }}>
            Access Level
          </h5>
          <div className="accountNameField1">
            <div
              className="terms-conditions pos-start"
              onClick={() => {
                setRole("Manager");
              }}
            >
              <img src={role == "Manager" ? checkedImage : uncheckedImage} />
              <label htmlFor="agreeTerms" id="agreeLabel">
                {" "}
                Manager{" "}
              </label>
            </div>
            <p className="selectOr">or</p>
            <div
              className="terms-conditions pos-start"
              onClick={() => {
                setRole("Employee");
              }}
            >
              <img src={role == "Employee" ? checkedImage : uncheckedImage} />
              <label htmlFor="agreeTerms" id="agreeLabel">
                {" "}
                Employee{" "}
              </label>
            </div>
          </div>
          <button
            className="bt-primary mb50"
            onClick={() => {
              AddHandler();
            }}
          >
            Send Invite
          </button>
        </div>
        <div>
          <h1 className="mt10" style={{ fontSize: 25 }}>
            Users that have access to your account
          </h1>
          <div className="accountHeader">
            <h1
              className="mt10"
              style={{ fontSize: 25, width: "33%", textAlign: "center" }}
            >
              Name
            </h1>
            <h1
              className="mt10"
              style={{ fontSize: 25, width: "33%", textAlign: "center" }}
            >
              Email
            </h1>
            <h1
              className="mt10"
              style={{ fontSize: 25, width: "33%", textAlign: "center" }}
            >
              Access Type
            </h1>
          </div>
          {userList.map((item, index) => (
            <div key={index} className="accountListArea">
              <div className="accountHeader">
                <h1
                  className="mt10"
                  style={{
                    fontSize: 20,
                    color: "#707070",
                    width: "33%",
                    textAlign: "center",
                  }}
                >
                  {item.fristName} {item.lastName}
                </h1>
                <h1
                  className="mt10"
                  style={{
                    fontSize: 20,
                    color: "#707070",
                    width: "33%",
                    textAlign: "center",
                  }}
                >
                  {item.email}
                </h1>
                <h1
                  className="mt10"
                  style={{
                    fontSize: 20,
                    color: "#707070",
                    width: "33%",
                    textAlign: "center",
                  }}
                >
                  {item.role}
                </h1>
              </div>
              <div className="accountDeleteBtn">
                <button
                  className="removeAccountBtn"
                  onClick={() => {
                    removeAccess(index);
                  }}
                >
                  Remove Access
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ToastContainer />
      {uploading && (
        <Spinner
          animation="border"
          variant="primary"
          className="loading-border"
        />
      )}
    </div>
  );
}

export default AccountSharingScreen;
