import React, { useState, useEffect } from "react";
import GoBackButton from "../components/GoBackButton";
import LogoWithUserType from "../components/LogoWithUserType";
import CustomInput from "../components/CustomInput1";
import clip from "../images/clip.png";
import greenPhone from "../images/greenPhone.png";
import maple from "../images/maple.png";
import { useHistory, Link } from "react-router-dom";

import consumers_logo from "../images/consumers_logo.png";
import dispensaries_logo from "../images/dispensaries_logo.png";
import drivers_logo from "../images/drivers_logo.png";

// Integration module
import App, { firestore } from "../config/firebase";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { injectStyle } from "react-toastify/dist/inject-style";
import Spinner from "react-bootstrap/Spinner";

import DispensaryRequestModal from "../components/DispensaryRequestModal";

var usertype = "consumer";

if (typeof window !== "undefined") {
  injectStyle();
}

let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

export default function LoginScreen() {
  const history = useHistory();

  const [isConsumers, setIsConsumers] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [isDispensaries, setIsDispensaries] = useState(true);
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestModalShow, setRequestModalShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    usertype = "dispensary";
    localStorage.setItem("usertype", "dispensary");
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("loggedIn") === "true" &&
      localStorage.getItem("usertype") === "consumer"
    ) {
      history.push("/");
    } else if (
      localStorage.getItem("loggedIn") === "true" &&
      localStorage.getItem("usertype") === "dispensary"
    ) {
      history.push("/dispensarylanding");
    } else if (
      localStorage.getItem("loggedIn") === "true" &&
      localStorage.getItem("usertype") === "driver"
    ) {
      history.push("/profile");
    } else {
      localStorage.setItem("usertype", "dispensary");
      localStorage.setItem("loggedIn", "false");
    }
  }, []);

  const login = async () => {
    if (email == "" || pwd == "") {
      toast("Please fill all fields!");
    } else if (reg.test(email) === false) {
      toast("Please enter vaild email!");
    } else {
      setLoading(true);
      try {
        await App.auth()
          .signInWithEmailAndPassword(email, pwd)
          .then((res) => {
            setLoading(false);
            firestore
              .collection("User")
              .doc(res.user.uid)
              .onSnapshot((documentSnapshot) => {
                let fetchUserData = documentSnapshot.data();
                if (fetchUserData !== undefined) {
                  if (fetchUserData.userType == usertype) {
                    localStorage.setItem("userUid", res.user.uid);
                    localStorage.setItem("loggedIn", "true");
                    localStorage.setItem("userRole", "Admin");
                    localStorage.setItem("storeName", fetchUserData.storeName);
                    localStorage.setItem("storeNameUrl", fetchUserData.storeName.split(' ').join('-'))
                    history.push("/");
                  } else {
                    toast("Sorry, this email is already in use.");
                  }
                } else {
                  firestore
                    .collection("Employee")
                    .onSnapshot((documentSnapshot) => {
                      documentSnapshot.forEach((snapshot) => {
                        var employeeList = snapshot.data().employeeList;
                        employeeList.forEach((employee) => {
                          if (employee.email == email) {
                            if (employee.isDelected == false) {
                              localStorage.setItem("userUid", employee.storeId);
                              localStorage.setItem("loggedIn", "true");
                              localStorage.setItem("userRole", employee.role);
                              firestore
                                .collection("User")
                                .doc(employee.storeId)
                                .onSnapshot((documentSnapshot) => {
                                  let fetchStoreData = documentSnapshot.data();
                                  localStorage.setItem(
                                    "storeName",
                                    fetchStoreData.storeName
                                  );
                                  localStorage.setItem("storeNameUrl", fetchStoreData.storeName.split(' ').join('-'))
                                  history.push("/");
                                });
                            } else {
                              toast(
                                "Sorry, you can't access with this account."
                              );
                            }
                          }
                        });
                      });
                    });
                }
              });
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.message ==
              "A network error (such as timeout, interrupted connection or unreachable host) has occurred."
            ) {
              toast("Your internet Connection is failed");
            }
            if (
              error.message ==
              "There is no user record corresponding to this identifier. The user may have been deleted."
            ) {
              toast("This email does not exist. Please create an account.");
            }
            if (
              error.message ==
              "The password is invalid or the user does not have a password."
            ) {
              toast(
                "You have entered the wrong email or password. Please try again."
              );
            }
          });
      } catch (error) {
        alert(error);
      }
    }
  };
  function changeSecond() {
    switchUser(2);
  }
  async function switchUser(id) {
    // eslint-disable-next-line default-case
    switch (id) {
      case 1:
        setIsConsumers(true);
        setIsDispensaries(false);
        setIsDriver(false);
        // setUsertype("consumer")
        usertype = "consumer";
        await localStorage.setItem("usertype", "consumer");
        break;
      case 2:
        setIsConsumers(false);
        setIsDispensaries(true);
        setIsDriver(false);
        // setUsertype("dispensary")
        usertype = "dispensary";
        await localStorage.setItem("usertype", "dispensary");
        break;
      case 3:
        setIsConsumers(false);
        setIsDispensaries(false);
        setIsDriver(true);
        // setUsertype("dispensary")
        usertype = "driver";
        await localStorage.setItem("usertype", "driver");
        break;
    }
    // usertype = localStorage.getItem("usertype")
  }

  const onSubmit = () => {
    setRequestModalShow(false);
  };

  const handleKeypress = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <section className="section">
      <DispensaryRequestModal
        show={requestModalShow}
        onSubmit={onSubmit}
        onHide={() => setRequestModalShow(false)}
      />
      <div className="back-cont-login">
        <GoBackButton login />
      </div>
      <div className="checkout-form-cont">
        <LogoWithUserType
          userType={
            isConsumers
              ? "for Consumers"
              : isDispensaries
              ? "for Stores"
              : "for drivers"
          }
          logo={
            isConsumers
              ? consumers_logo
              : isDispensaries
              ? dispensaries_logo
              : drivers_logo
          }
        />
        <CustomInput
          placeholder={
            isDispensaries ? "Owner's Email Address" : "Email Address"
          }
          type="email"
          iconType="email"
          onChange={(e) => setEmail(e.target.value)}
          val={email}
          onKeyPress={handleKeypress}
        />
        <CustomInput
          placeholder="Password"
          type="password"
          iconType="password"
          onChange={(e) => setPwd(e.target.value)}
          val={pwd}
          onKeyPress={handleKeypress}
        />
        <div className="forgot-password-txt-pos">
          <Link to={loading ? "#" : "/forgot"} className="forgot-pwd-txt">
            Forgot Password?
          </Link>
        </div>
        <button onClick={login} className="bt-primary mt50" disabled={loading}>
          Sign in
          {loading && <Spinner animation="border" variant="light" />}
        </button>

        <p className="greyText s31 mt90">Don't have an account?</p>
        {isConsumers ? (
          <Link
            to={loading ? "#" : "/signup"}
            className="main s32 mb90 z-index-1"
          >
            Sign up
          </Link>
        ) : (
          <button
            onClick={() => setRequestModalShow(true)}
            className="main s32 mb90 z-index-1 signupModal"
          >
            Sign up
          </button>
        )}
      </div>
      <div className="clipArea">
        <img src={clip} alt="clip" className="clip" />
      </div>
      <div className={isDriver ? "greenAreaDriver" : "greenArea"}>
        <img
          src={greenPhone}
          alt="green"
          className={isDriver ? "greenPhoneDriver" : "greenPhone"}
        />
      </div>
      <div
        className={
          isConsumers
            ? "greenAreaCopy"
            : isDispensaries
            ? "greenAreaCopyDispensaries"
            : "greenAreaCopyDriver"
        }
      >
        <img
          src={greenPhone}
          alt="green"
          className={
            isConsumers
              ? "greenPhoneCopy"
              : isDispensaries
              ? "greenPhoneCopyDispensaries"
              : "greenPhoneCopyDriver"
          }
        />
      </div>
      <div
        className={
          isConsumers
            ? "mapleArea"
            : isDispensaries
            ? "mapleAreaDispensaries"
            : "mapleAreaDriver"
        }
      >
        <img src={maple} alt="green" className="maple" />
      </div>
      <ToastContainer />
    </section>
  );
}
