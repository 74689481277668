import React, { useEffect, useState, useCallback } from 'react'
import { database } from '../config/firebase'
import atl_zipCode from '../constants/zipCode'
import CurrencyInput from './CurrencyInput.js'

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { injectStyle } from "react-toastify/dist/inject-style";

const OrderSummary = () => {
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([])
    const [zip, setZip] = useState([])
    const [taxFee, setTaxFee] = useState(0)
    const [value, setValue] = useState(0)
    const [userData, setUserData] = useState("")
    const [referralFlag, setReferralFlag] = useState(false)
    const [referralValue, setReferralValue] = useState(0)
    useEffect(() => {
        let userID = localStorage.getItem('userUid')
        // localStorage.getItem('zip') && setZip(localStorage.getItem('zip'))
        let zipCode = localStorage.getItem('zip')
        if (atl_zipCode.zip700.indexOf(zipCode) >= 0) {
            setTaxFee(0.07)
        } else if (atl_zipCode.zip775.indexOf(zipCode) >= 0) {
            setTaxFee(0.0775)
        } else if (atl_zipCode.zip800.indexOf(zipCode) >= 0) {
            setTaxFee(0.08)
        } else {
            setTaxFee(0.089)
        }

        database
            .ref('user/' + userID + '/consumer')
            .once("value", (snapshot) => {
                let user_data = {
                    email: snapshot.val().email,
                    firstName: snapshot.val().fristName,
                    lastName: snapshot.val().lastName,
                    password: snapshot.val().password,
                    phoneNum: snapshot.val().phoneNum,
                    profileimage: snapshot.val().profileimage,
                    userType: snapshot.val().userType,
                    zipCode: snapshot.val().zipCode,
                    age: snapshot.val().age,
                    gender: snapshot.val().gender,
                    birthday: snapshot.val().birthday,
                    promoCode: snapshot.val().promoCode,
                    referralValue: snapshot.val().referralValue,
                };
                setUserData(user_data)
            })

        database
            .ref("Carts/" + userID)
            .once("value", (snapshot) => {
                var data = []
                var row
                snapshot.forEach(element => {
                    row = {
                        "Description": element.val().Description,
                        "GpriceValue": element.val().GpriceValue,
                        "Tag": element.val().Tag,
                        "feeValue": element.val().feeValue,
                        "id": element.key,
                        "itemImage": element.val().itemImage,
                        "itemNum1": element.val().itemNum1,
                        "priceValue": element.val().priceValue,
                        "productName": element.val().productName,
                        "num": element.val().num,
                        "coaImage": element.val().coaImage,
                        "storeId": element.val().storeId,
                    }
                    data.push(row)
                });
                setItems(data)
                let totalAmount = 0;
                data
                    .map((item) => {
                        totalAmount = totalAmount + (item.priceValue * item.num);
                        setTotal(totalAmount)
                    })
            })
    }, [])

    const handleValueChange = useCallback(val => {
        setValue(val);
        setReferralValue(parseFloat(val / 100).toFixed(2));
    }, []);

    const applyReferral = () => {
        if (referralValue > userData.referralValue) {
            setReferralFlag(false)
            toast("Sorry, You do not have the amount you have chosen.")
        } else {
            let userID = localStorage.getItem('userUid')
            setReferralFlag(true)
            database.ref("ReferralValue/" + userID).update({
                referralValue: parseFloat(referralValue),
            })
            toast("The Referral Discount has been successfully applied.")
        }
    }

    return (
        <div className="order-summary-cont">
            <div className="order-summary-head">
                <p>Order Summary</p>
            </div>
            <div className="order-summary-body">
                {items
                    .map((item) => <div key={item.id} className="order-summary-order">
                        <p>{item.productName} x{item.num}</p>
                        <p>${parseFloat(item.priceValue * item.num).toFixed(2)}</p>
                    </div>)}
                <div className="order-summary-order">
                    <p>Service Fee</p>
                    <p>$5.00</p>
                </div>
                <div className="order-summary-order">
                    <p>Delivery Fee</p>
                    <p>$5.00</p>
                </div>
                <div className="order-summary-order">
                    <p>State and Sales Tax</p>
                    <p>${((total) * taxFee).toFixed(2)}</p>
                </div>
                {referralFlag == true? <div className="order-summary-order">
                    <p style={{ color: '#61D273' }}>Referral Discount</p>
                    <p style={{ color: '#61D273' }}>-${referralValue}</p>
                </div>:""}
            </div>
            <div className="order-summary-total">
                <div className="order-summary-order">
                    <p>Total Amount</p>
                    {referralFlag == true? 
                    <p className="total-amount">${parseFloat(total + total * taxFee + 10-referralValue).toFixed(2)}</p>
                    :<p className="total-amount">${parseFloat(total + total * taxFee + 10).toFixed(2)}</p>}
                </div>
            </div>
            <p className="referalValue">Available Referral Balance<span className="promocodeArea"> ${parseFloat(userData.referralValue).toFixed(2)}</span></p>
            <div className="applyReferalValue">
                <button className="referalApplyBtn" onClick={applyReferral}>Apply</button>
                <CurrencyInput
                    max={100000000}
                    onValueChange={handleValueChange}
                    style={{ textAlign: 'center', width: 80, margin: 10 }}
                    value={value}
                />
                <p>to checkout</p>
            </div>
        </div>
    )
}
export default OrderSummary;
