import React, { useEffect, useState } from "react";
import ProductCard from "../components/ProductCard";
import { firestore } from "../config/firebase";
import Spinner from "react-bootstrap/Spinner";

const DispensaryOnlineProducts = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const userId = localStorage.getItem("userUid");
  useEffect(() => {
    const getShopItems = async () => {
      setLoading(true);
      const itemsRef = firestore.collection("Items");
      const snapshot = await itemsRef.where("storeId", "==", userId).get();
      if (snapshot.empty) {
        return;
      }

      let data = [];
      let row = {};
      snapshot.forEach((doc) => {
        row = {
          Description: doc.data().Description,
          GpriceValue: doc.data().GpriceValue,
          Tag: doc.data().Tag,
          feeValue: doc.data().feeValue,
          id: doc.id,
          itemImage: doc.data().itemImage,
          itemNum1: doc.data().itemNum1,
          priceValue: doc.data().priceValue,
          productName: doc.data().productName,
          storeId: doc.data().storeId,
        };
        data.push(row);
      });
      setData(data);
      setLoading(false);
    };

    getShopItems();
  }, []);
  return (
    <section className="shop-products">
      <div className="store-container scrollable" id="products">
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Spinner
              animation="border"
              variant="primary"
              style={{ width: 50, height: 50 }}
            />
          </div>
        ) : (
          <div className="products-cont">
            {data && data.map((el) => <ProductCard key={el.id} {...el} />)}
          </div>
        )}
      </div>
    </section>
  );
};
export default DispensaryOnlineProducts;
