import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Blog from "../components/Blog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import PressFeatures from "../components/PressFeatures";
import Testemonials from "../components/Testemonials";
import ShopLocal from "../components/ShopLocal";
import ShopProducts from "../components/ShopProducts";
import LocationSettingModal from "../components/LocationSettingModal";
import Linkimage from "../images/cannago_link_share_image.PNG";
// Firebase Integration Module
import { firestore } from "../config/firebase";
import Spinner from "react-bootstrap/Spinner";

import Geocode from "react-geocode";
import { getDistance, convertDistance } from "geolib";
import SearchModal from "../components/SearchModal";
import AgeCheckModal from "../components/AgeCheckModal";
import AgeErrorModal from "../components/AgeErrorModal";

// if (typeof window !== "undefined") {
//     injectStyle();
// }

Geocode.setApiKey("AIzaSyAUuy6Ir1exU6HybTu8H4j5ZH6ZG4MRFm0");

// set response language. Defaults to english.
Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("es");

const LandingScreen = () => {
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [dropAddress, setDropAddress] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [openStoreID, setOpenStoreID] = useState([]);
  const [product, setProduct] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [searchModalShow, setSearchModalShow] = useState(false);
  const [ageModalShow, setEgeModalShow] = useState(true);
  const [ageErrorModalShow, setEgeErrorModalShow] = useState(false);
  const [num, setNum] = useState(0);
  const [opentStatus, setOpentStatus] = useState(false);
  const [storeFlag, setStoreFlag] = useState(true);
  const [distanceFlat, setDistanceFlat] = useState(false);
  const [recongFlat, setRecongFlat] = useState(1);
  const [loginStatus, setLoginStatus] = useState("");

  let today = new Date();
  let today_Hour = today.getHours();
  let today_minute = today.getMinutes();
  let now_Mins = today_Hour * 60 + today_minute;
  let loggedIn = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getCalStoreTime = (tempData) => {
    var start_Time = tempData.startTime.split(" ")[0];
    var flag_start_Time = tempData.startTime.split(" ")[1];
    var end_Time = tempData.endTime.split(" ")[0];
    var flag_end_Time = tempData.endTime.split(" ")[1];
    var start_Time_Mins =
      flag_start_Time === "AM"
        ? parseInt(start_Time.split(":")[0]) * 60 +
          parseInt(start_Time.split(":")[1])
        : (parseInt(start_Time.split(":")[0]) + 12) * 60 +
          parseInt(start_Time.split(":")[1]);
    var end_Time_Mins =
      flag_end_Time === "PM"
        ? (parseInt(end_Time.split(":")[0]) + 12) * 60 +
          parseInt(end_Time.split(":")[1])
        : parseInt(end_Time.split(":")[0]) * 60 +
          parseInt(end_Time.split(":")[1]);
    if (now_Mins >= start_Time_Mins && now_Mins <= end_Time_Mins) {
      tempData["timeOpenStatus"] = true;
      setOpentStatus(true);
    } else {
      tempData["timeOpenStatus"] = false;
      setOpentStatus(false);
    }
    return tempData;
  };

  const getInitialData = async () => {
    const userId = await localStorage.getItem("userUid");
    loggedIn = await localStorage.getItem("loggedIn");
    setLoginStatus(loggedIn);
    const today = new Date();
    const day = today.getDay();
    let currentAddress = "";
    let currentCity = "";
    let currentZip = "";
    let currentlat = "";
    let currentlng = "";
    let storelat = "";
    let storelng = "";

    if (userId) {
      const userInfo = await firestore.collection("User").doc(userId).get();
      if (!userInfo.empty) {
        if (userInfo.data().dropStreet) {
          currentAddress = userInfo.data().dropStreet;
          currentCity = userInfo.data().dropCity;
          currentZip = userInfo.data().dropZip;
          localStorage.setItem("dropAddress", userInfo.data().dropAddress);
          localStorage.setItem("disStreet", currentAddress);
          localStorage.setItem("city", currentCity);
          localStorage.setItem("zip", currentZip);
          setDropAddress(userInfo.data().dropAddress);
        }
      }
    }

    var data = [];
    var data1 = [];
    var realdata = [];
    var openStoreData = [];
    var storeIdList = [];
    var row = {};
    var realDistance;

    const userDataRef = firestore.collection("User");
    const userQuery = await userDataRef
      .where("userType", "==", "dispensary")
      .where("storeItemAmount", ">=", 3);

    userQuery.onSnapshot(async (documentSnapshot) => {
      if (documentSnapshot.empty) {
      } else {
        for (let i = 0; i < documentSnapshot.docs.length; i++) {
          const element = documentSnapshot.docs[i];
          const el = element.data();
          let tempData = {
            id: element.id,
            ImageUrl: el.profileimage,
            usertype: el.userType,
            startTime: el.storeHours[day].startTime,
            endTime: el.storeHours[day].endTime,
            openStatus: el.storeHours[day].openStatus,
            online: el.storeHours[day].online,
            storeName: el.storeName,
            storeStreetAdress: el.storeStreetAdress,
          };
          data.push(tempData);
        }
      }

      if (loggedIn === "true") {
        if (currentAddress) {
          Geocode.fromAddress(currentAddress).then(
            (response) => {
              const { lat, lng } = response.results[0].geometry.location;
              currentlat = lat.toString();
              currentlng = lng.toString();
              data.forEach((element) => {
                if (element.online == true) {
                  Geocode.fromAddress(element.storeStreetAdress)
                    .then(
                      async (response) => {
                        const { lat, lng } =
                          response.results[0].geometry.location;
                        storelat = lat.toString();
                        storelng = lng.toString();
                        if (storelat && storelng) {
                          let distance = await getDistance(
                            {
                              latitude: currentlat,
                              longitude: currentlng,
                            },
                            { latitude: storelat, longitude: storelng }
                          );
                          realDistance = convertDistance(distance, "mi");
                          if (realDistance < 35) {
                            const resTempData = getCalStoreTime(element);
                            openStoreData.push(resTempData.id);
                            realdata.push(resTempData);
                            setDistanceFlat(true);
                          }

                          setStoreData(realdata);
                          getOpenStoreData(openStoreData);
                        }
                      },
                      (error) => {}
                    )
                    .catch((error) => {
                      console.log("error", error);
                    });
                }
              });
            },
            (error) => {
              console.error("error", error);
            }
          );
        } else {
          setDistanceFlat(true);
          data.forEach((element) => {
            if (element.online == true) {
              const resTempData = getCalStoreTime(element);
              openStoreData.push(resTempData.id);
              realdata.push(resTempData);
            }
          });
          setStoreData(realdata);
          getOpenStoreData(openStoreData);
        }
      } else {
        data.forEach((element) => {
          if (element.online == true) {
            const resTempData = getCalStoreTime(element);
            openStoreData.push(resTempData.id);
            realdata.push(resTempData);
          }
        });
        setStoreData(realdata);
        getOpenStoreData(openStoreData);
      }
    });

    // Fetching store data

    // checking login status
    if (loggedIn === "true") {
      const userId = await localStorage.getItem("userUid");
      const userDataSnapshot = await firestore
        .collection("User")
        .doc(userId)
        .get();
      const userData = userDataSnapshot.data();
      localStorage.setItem("url", userData.profileimage);
      if (localStorage.getItem("usertype") === "dispensary") {
        localStorage.setItem("storeName", userData.storeName);
        history.push("/dispensarylanding");
      } else {
        localStorage.setItem(
          "username",
          userData.fristName + " " + userData.lastName.substr(0, 1)
        );
        setUsername(userData.fristName + " " + userData.lastName.substr(0, 1));
        if (localStorage.getItem("usertype") === "driver")
          history.push("/profile");
        else history.push("/");
      }
      // Fetching userinfo(username, profile image)
    } else {
      history.push("/");
    }
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const getOpenStoreData = async (storeData) => {
    console.log("storeData", storeData);
    let itemData = [];
    if (storeData.length === 0) {
      itemData = await firestore
        .collection("Items")
        .where("storeId", "==", "XFoCAU7xsCgB1y3i8sFDCbe2Evm2")
        .get();
    } else {
      itemData = await firestore
        .collection("Items")
        .where("storeId", "in", storeData.splice(0, 9))
        .get();
    }
    let productArray = [];
    if (!itemData.empty) {
      itemData.forEach((element) => {
        let elementData = element.data();
        productArray.push(elementData);
      });
      productArray.reverse();
      setProduct(productArray);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("dropAddress"))
      setDropAddress(localStorage.getItem("dropAddress"));
  }, [localStorage.getItem("dropAddress")]);

  const modalHandler = () => {
    setModalShow(true);
  };
  const searchModalPress = () => {
    setSearchModalShow(true);
  };

  const autoRefresh = () => {
    setNum(num + 1);
  };

  const ageCloseHandler = () => {
    setEgeModalShow(false);
    localStorage.setItem("ageFlag", "true");
  };

  return (
    <>
      <LocationSettingModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <SearchModal
        show={searchModalShow}
        dropAddress={dropAddress}
        onHide={() => setSearchModalShow(false)}
      />
      <AgeErrorModal
        show={ageErrorModalShow}
        onHide={() => setEgeErrorModalShow(false)}
      />
      {localStorage.getItem("ageFlag") !== "true" && (
        <AgeCheckModal
          show={ageModalShow}
          onHide={ageCloseHandler}
          onAgeErrorHandler={() => setEgeErrorModalShow(true)}
        />
      )}
      <Navbar
        username={username}
        modalPress={modalHandler}
        searchModalPress={searchModalPress}
      />
      {loginStatus === "true" ? "" : <Header />}
      {/* {!(username && product && storeData) ?
                localStorage.getItem('loggedIn') === 'true' ? '' :
                    <div>
                        <ShopLocal data={storeData} dropAddress={dropAddress} modalPress={modalHandler} reFresh={autoRefresh} />
                        <ShopProducts data={product} opentStatus={opentStatus} dropAddress={dropAddress} modalPress={modalHandler} />
                        <Blog />
                        <Footer />
                    </div> :
                storeData === [] || storeData === null || storeData === undefined || storeData.length < 1 ?
                    <div className="emptyErea">
                        <p className="emptyEearTitle">
                            Shop Local Stores
                        </p>
                        <p className="emptyEearText">
                            Sorry Bud, they're currently no stores in your area. We're working to expand our reach.
                        </p>
                        <Blog />
                        <Footer />
                    </div> :
                    <div>
                        <ShopLocal data={storeData} dropAddress={dropAddress} modalPress={modalHandler} reFresh={autoRefresh} />
                        <ShopProducts data={product} opentStatus={opentStatus} dropAddress={dropAddress} modalPress={modalHandler} />
                        <Blog />
                        <Footer />
                    </div>
            } */}
      {localStorage.getItem("loggedIn") === "true" ? (
        distanceFlat == false ? (
          recongFlat == 1 ? (
            <Spinner
              animation="border"
              variant="primary"
              className="loading-border"
            />
          ) : (
            <div className="emptyErea">
              <Testemonials />
              <PressFeatures />
              <Blog />
              <Footer />
            </div>
          )
        ) : username == "" || product.length == 0 ? (
          <Spinner
            animation="border"
            variant="primary"
            className="loading-border"
          />
        ) : (
          <div>
            <Testemonials />
            <PressFeatures />
            <ShopProducts
              data={product}
              opentStatus={opentStatus}
              dropAddress={dropAddress}
              modalPress={modalHandler}
            />
            <Blog />
            <Footer />
          </div>
        )
      ) : !(product && storeData) ? (
        <Spinner
          animation="border"
          variant="primary"
          className="loading-border"
        />
      ) : (
        <div>
          <Testemonials />
          <PressFeatures />
          <ShopProducts
            data={product}
            opentStatus={opentStatus}
            dropAddress={dropAddress}
            modalPress={modalHandler}
          />
          <Blog />
          <Footer />
        </div>
      )}
    </>
  );
};

export default LandingScreen;
