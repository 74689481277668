import React from "react";
import { useHistory, Link } from "react-router-dom";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import hypepotamus from "../images/hypepotamus.png";
import alantaInno from "../images/atlantainno.png";
import under25 from "../images/innounder25.png";
import beatthemaze from "../images/beatthemaze.jpg";
import differentleaf from "../images/differentleaf.png";
import Cashcolor from "../images/Cashcolor.png";

const Header = () => {
  let history = useHistory();

  //slider settigns
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          initialSlide: 1,
        },
      },
    ],
  };

  const gotoSignUp = () => {
    history.push("/login");
  };

  let sliderList = [
    {
      id: 1,
      imageUrl: under25,
      link: "https://www.bizjournals.com/atlanta/inno/stories/awards/2021/11/11/inno-under-25.html",
    },
    {
      id: 2,
      imageUrl: alantaInno,
      link: "https://www.bizjournals.com/atlanta/inno/stories/profiles/2021/07/14/cannago-startup-cbd-delivery-service.html",
    },
    {
      id: 3,
      imageUrl: hypepotamus,
      link: "https://hypepotamus.com/feature/cannago-atlanta/",
    },
    {
      id: 4,
      imageUrl: beatthemaze,
      link: "https://podcasts.apple.com/us/podcast/13-cannabis-as-a-business-the-power-of/id1613351762?i=1000564560257",
    },
    {
      id: 5,
      imageUrl: differentleaf,
      link: "https://podcasts.apple.com/us/podcast/sp22-e5-cannago-entrepreneurs-cbd-and-delta8-delivery/id1501758648?i=1000560997465",
    },
    {
      id: 6,
      imageUrl: Cashcolor,
      link: "https://www.youtube.com/watch?v=sPdLmsMwQGQ&t=8s",
    },
  ];

  return (
    <header>
      <div className="features-title">
        <h1 className="features-text">Featured</h1>
      </div>
      {/* <div className="press-page-container">
                <img src={under25} className="page-img" />
                <img src={alantaInno} className="page-img" />
                <img src={hypepotamus} className="hypepotamus" />
            </div> */}
      <div className="features-slider">
        <Slider className="slider" {...settings}>
          {sliderList.map((item, index) => (
            <div className="featureSliderItem" key={index}>
              <a
                className="featureSliderItem"
                style={{ margin: 0 }}
                href={item.link}
                target="_blank"
              >
                <img
                  style={{ margin: 0 }}
                  width="300"
                  src={item.imageUrl}
                  alt=""
                  key={item.id}
                />
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </header>
  );
};

export default Header;
