import React, { useState, useEffect } from "react";
import OrderConfirm from "./OrderConfirm";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "react-bootstrap/Spinner";
import { injectStyle } from "react-toastify/dist/inject-style";

import { firestore } from "../config/firebase";

if (typeof window !== "undefined") {
  injectStyle();
}

function OrderConfirmWrapper(props) {
  const [orderData, setOrderData] = useState("");
  const [completedData, setCompletedData] = useState("");
  const [userData, setuserData] = useState("");
  let userId = localStorage.getItem("userUid");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      let oderId;

      const OrderItemsQuery = await firestore
        .collection("OrderItems")
        .where("storeId", "==", userId);

      OrderItemsQuery.onSnapshot((documentSnapshot) => {
        setLoading(false);
        var orderedData = [];
        var completedData = [];
        var row;
        documentSnapshot.forEach((element) => {
          const orderItemData = element.data();
          if (
            !orderItemData.dropStatus &&
            orderItemData.placeStatus &&
            orderItemData.pendingStatus == "Authorized"
          ) {
            orderedData.push(orderItemData);
          } else if (
            orderItemData.dropStatus &&
            orderItemData.placeStatus &&
            orderItemData.pendingStatus == "Captured"
          ) {
            completedData.push(orderItemData);
          }
        });
        setOrderData(orderedData);
        setCompletedData(completedData);
      });
    };
    getData();
  }, []);

  return (
    <div className="order-cont-wrapper">
      {loading ? (
        <Spinner
          animation="border"
          variant="primary"
          className="loading-border"
        />
      ) : (
        <div>
          <div className="order-cont" id="orderHistory">
            <div className="addToStore mt90">
              <h1 className="mb10">Orders to Confirm</h1>
            </div>
            {orderData !== "" &&
            orderData !== null &&
            orderData.length !== 0 ? (
              orderData.map((el) => (
                <OrderConfirm
                  key={el.orderId}
                  {...el}
                  qty2={1}
                  usertype={props.usertype}
                  userData={userData}
                />
              ))
            ) : (
              <div className="mt90">
                <h2 className="mb10" style={{ color: "#61D270" }}>
                  There is no order to confirm
                </h2>
              </div>
            )}
            <div className="addToStore mt90">
              <h1 className="mb10">Order History</h1>
            </div>
            {completedData !== "" &&
            completedData !== null &&
            completedData.length !== 0 ? (
              completedData.map((el) => (
                <OrderConfirm
                  key={el.orderId}
                  {...el}
                  price1="$12.55"
                  paid
                  usertype={props.usertype}
                  userData={userData}
                />
              ))
            ) : (
              <div className="mt90">
                <h2 className="mb10" style={{ color: "#61D270" }}>
                  There is no order history
                </h2>
              </div>
            )}
          </div>
          <ToastContainer />
        </div>
      )}
    </div>
  );
}

export default OrderConfirmWrapper;
