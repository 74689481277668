import React, { useEffect, useState } from 'react'
import SignUpForm from '../components/SignUpForm'
import DispensarySignUpForm from '../components/DispensarySignUpForm'
import DriverSignUpForm from '../components/DriverSignUpForm'
import greenPhone from '../images/greenPhone.png'
import maple from '../images/maple2.png'
import DispensaryStations from '../components/DispensaryStations'
import Profile from '../components/Profile'
import GoBackButton from '../components/GoBackButton'
import Avatar from '../images/avatar.png'
// import DispensaryNavbar from '../components/DispensaryNavbar'

export default function SignupScreen() {
  const [userType, setUserType] = useState('')

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  useEffect(() => {
    setUserType(localStorage.getItem('usertype'))
  }, [])

  return (
    <>
      {/* <div className="back-cont-login"><GoBackButton /></div> */}
      <DispensarySignUpForm />
    </>
  )
}
