import React from 'react'
import WebViewCheckoutForm from '../components/WebViewCheckoutForm'
import { useEffect } from 'react'
const WebViewCheckoutScreen = (props) => {
    let userID = props.match.params.id
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <div className="check-out-screen">
            <WebViewCheckoutForm userID={userID}/>
        </div>
    )
}
export default WebViewCheckoutScreen;