import React from 'react';
import Helmet from 'react-helmet'
// import test from './test'

// importing MyRouts where we located all of our theme
import MyRouts from './routers/Routes'

function App() {
  return (
    <main>
      <Helmet>
        {/*<!-- HTML Meta Tags -->*/}
        <title>CannaGo Delta, Hemp & CBD Delivery</title>
        <meta name="description" content="Your neighborhood's best buds are safely delivered to your door with CannaGo: Your bud in delivery! Browse your favorite legal Delta 8, CBD, and Hemp products and get them delivered same-day with CannaGo!" />

        {/*<!-- Google / Search Engine Tags -->*/}
        <meta itemprop="name" content="CannaGo Delta, Hemp & CBD Delivery" />
        <meta itemprop="description" content="Your neighborhood's best buds are safely delivered to your door with CannaGo: Your bud in delivery! Browse your favorite legal Delta 8, CBD, and Hemp products and get them delivered same-day with CannaGo!" />
        <meta itemprop="image" content="http://trycannago.com/static/cannago_link_share_image.PNG" />
        <link
          rel="shortcut icon"
          type="image/jpg"
          href="http://trycannago.com/static/cannago_link_share_image.PNG"
        />

        {/*<!-- Facebook Meta Tags -->*/}
        <meta property="og:url" content="https://trycannago.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="CannaGo Delta, Hemp & CBD Delivery" />
        <meta property="og:description" content="Your neighborhood's best buds are safely delivered to your door with CannaGo: Your bud in delivery! Browse your favorite legal Delta 8, CBD, and Hemp products and get them delivered same-day with CannaGo!" />
        <meta property="og:image" content="http://trycannago.com/static/cannago_link_share_image.PNG" />

        {/*<!-- Twitter Meta Tags -->*/}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="CannaGo Delta, Hemp & CBD Delivery" />
        <meta name="twitter:description" content="Your neighborhood's best buds are safely delivered to your door with CannaGo: Your bud in delivery! Browse your favorite legal Delta 8, CBD, and Hemp products and get them delivered same-day with CannaGo!" />
        <meta name="twitter:image" content="http://trycannago.com/static/cannago_link_share_image.PNG" />
      </Helmet>
      <div>
        <MyRouts />
      </div>
    </main>
  );
}

export default App;
