"use strict";

var ApiContracts = require("authorizenet").APIContracts;
var ApiControllers = require("authorizenet").APIControllers;
var SDKConstants = require("authorizenet").Constants;
var utils = require("../utils.js");
var constants = require("../constants.js");

function authorizeCreditCard(callback, data) {
  var merchantAuthenticationType =
    new ApiContracts.MerchantAuthenticationType();
  merchantAuthenticationType.setName(constants.apiLoginKey);
  merchantAuthenticationType.setTransactionKey(constants.transactionKey);

  // var refId = new ApiContracts.ANetApiRequest();
  // refId.setRefId('123123123');
  var creditCard = new ApiContracts.CreditCardType();
  creditCard.setCardNumber(data.cardNum);
  creditCard.setExpirationDate(data.expDate);
  creditCard.setCardCode(data.cvc);

  var paymentType = new ApiContracts.PaymentType();
  paymentType.setCreditCard(creditCard);

  var orderDetails = new ApiContracts.OrderType();
  var date = new Date();
  var invoiceNum = date.getTime() + Math.floor(Math.random() * 100);
  orderDetails.setInvoiceNumber(invoiceNum.toString);
  orderDetails.setDescription("cbd delivery order");

  var tax = new ApiContracts.ExtendedAmountType();
  tax.setAmount(data.salesTax);
  tax.setName("sales tax");
  tax.setDescription("sales tax");

  var duty = new ApiContracts.ExtendedAmountType();
  duty.setAmount("0");
  duty.setName("duty name");
  duty.setDescription("duty description");

  var shipping = new ApiContracts.ExtendedAmountType();
  shipping.setAmount("5");
  shipping.setName("shipping name");
  shipping.setDescription("shipping description");

  var billTo = new ApiContracts.CustomerAddressType();
  billTo.setFirstName(data.firstName);
  billTo.setLastName(data.lastName);
  billTo.setCompany("");
  billTo.setAddress("");
  billTo.setCity("");
  billTo.setState("GA");
  billTo.setZip(data.cardZip);
  billTo.setCountry("USA");

  var shipTo = new ApiContracts.CustomerAddressType();
  shipTo.setFirstName(data.firstName);
  shipTo.setLastName(data.lastName);
  shipTo.setCompany("");
  shipTo.setAddress(data.delivereyStreet);
  shipTo.setCity(data.devliveryCity);
  shipTo.setState("GA");
  shipTo.setZip(data.devliveryZip);
  shipTo.setCountry("USA");

  var lineItemList = [];
  data.items.forEach((element) => {
    var lineItem_id = new ApiContracts.LineItemType();
    lineItem_id.setItemId(element.id);
    lineItem_id.setName(element.productName);
    lineItem_id.setDescription(element.Description);
    lineItem_id.setQuantity(element.num);
    lineItem_id.setUnitPrice(element.priceValue);
    lineItemList.push(lineItem_id);
  });

  var lineItems = new ApiContracts.ArrayOfLineItem();
  lineItems.setLineItem(lineItemList);

  var userField_a = new ApiContracts.UserField();
  userField_a.setName("A");
  userField_a.setValue("Aval");

  var userField_b = new ApiContracts.UserField();
  userField_b.setName("B");
  userField_b.setValue("Bval");

  var userFieldList = [];
  userFieldList.push(userField_a);
  userFieldList.push(userField_b);

  var userFields = new ApiContracts.TransactionRequestType.UserFields();
  userFields.setUserField(userFieldList);

  var transactionSetting1 = new ApiContracts.SettingType();
  transactionSetting1.setSettingName("duplicateWindow");
  transactionSetting1.setSettingValue("3600");

  var transactionSetting2 = new ApiContracts.SettingType();
  transactionSetting2.setSettingName("recurringBilling");
  transactionSetting2.setSettingValue("false");

  var transactionSettingList = [];
  transactionSettingList.push(transactionSetting1);
  transactionSettingList.push(transactionSetting2);

  var transactionSettings = new ApiContracts.ArrayOfSetting();
  transactionSettings.setSetting(transactionSettingList);

  var transactionRequestType = new ApiContracts.TransactionRequestType();
  transactionRequestType.setTransactionType(
    ApiContracts.TransactionTypeEnum.AUTHONLYTRANSACTION
  );
  transactionRequestType.setPayment(paymentType);
  transactionRequestType.setAmount(data.productAmount);
  transactionRequestType.setLineItems(lineItems);
  transactionRequestType.setUserFields(userFields);
  transactionRequestType.setOrder(orderDetails);
  transactionRequestType.setTax(tax);
  transactionRequestType.setDuty(duty);
  transactionRequestType.setShipping(shipping);
  transactionRequestType.setBillTo(billTo);
  transactionRequestType.setShipTo(shipTo);
  transactionRequestType.setTransactionSettings(transactionSettings);

  var createRequest = new ApiContracts.CreateTransactionRequest();
  createRequest.setMerchantAuthentication(merchantAuthenticationType);
  createRequest.setTransactionRequest(transactionRequestType);
  createRequest.setRefId(data.refId);

  //pretty print request
  console.log(JSON.stringify(createRequest.getJSON(), null, 2));

  //production mode definition.
  var ctrl = new ApiControllers.CreateTransactionController(
    createRequest.getJSON()
  );

  ctrl.setEnvironment(SDKConstants.endpoint.production);

  ctrl.execute(function () {
    var apiResponse = ctrl.getResponse();

    var response = new ApiContracts.CreateTransactionResponse(apiResponse);

    //pretty print response
    console.log(JSON.stringify(response, null, 2));

    if (response != null) {
      if (
        response.getMessages().getResultCode() ==
        ApiContracts.MessageTypeEnum.OK
      ) {
        if (response.getTransactionResponse().getMessages() != null) {
          console.log(
            "Successfully created transaction with Transaction ID: " +
              response.getTransactionResponse().getTransId()
          );
          console.log(
            "Response Code: " +
              response.getTransactionResponse().getResponseCode()
          );
          console.log(
            "Message Code: " +
              response
                .getTransactionResponse()
                .getMessages()
                .getMessage()[0]
                .getCode()
          );
          console.log(
            "Description: " +
              response
                .getTransactionResponse()
                .getMessages()
                .getMessage()[0]
                .getDescription()
          );
        } else {
          console.log("Failed Transaction.");
          if (response.getTransactionResponse().getErrors() != null) {
            console.log(
              "Error Code: " +
                response
                  .getTransactionResponse()
                  .getErrors()
                  .getError()[0]
                  .getErrorCode()
            );
            console.log(
              "Error message: " +
                response
                  .getTransactionResponse()
                  .getErrors()
                  .getError()[0]
                  .getErrorText()
            );
          }
        }
      } else {
        console.log("Failed Transaction.");
        if (
          response.getTransactionResponse() != null &&
          response.getTransactionResponse().getErrors() != null
        ) {
          console.log(
            "Error Code: " +
              response
                .getTransactionResponse()
                .getErrors()
                .getError()[0]
                .getErrorCode()
          );
          console.log(
            "Error message: " +
              response
                .getTransactionResponse()
                .getErrors()
                .getError()[0]
                .getErrorText()
          );
        } else {
          console.log(
            "Error Code: " + response.getMessages().getMessage()[0].getCode()
          );
          console.log(
            "Error message: " + response.getMessages().getMessage()[0].getText()
          );
        }
      }
    } else {
      console.log("Null Response.");
    }

    callback(response);
  });
}

if (require.main === module) {
  authorizeCreditCard(function () {
    console.log("authorizeCreditCard call complete.");
  });
}

module.exports.authorizeCreditCard = authorizeCreditCard;
