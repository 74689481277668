import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { storage, firestore } from "../config/firebase";
import Spinner from "react-bootstrap/Spinner";

import DispensaryNavbar from "../components/DispensaryNavbar";
import AddItmeImage from "../components/AddItmeImage";
import UpdateItemSettings from "../components/UpdateItemSettings";
import GoBackButton from "../components/GoBackButton";
import BG from "../images/forgot2.png";
import Footer from "../components/Footer";

import avatar from "../images/avatar.png";

function StoreUpdateScreen(props) {
  // let itemId = props.match.params.id.split('--|')[1];
  let itemId = props.match.params.id;
  let userId = localStorage.getItem("userUid");
  const location = useLocation();

  const [url, setUrl] = useState("");
  const [coaUrl, setCoaUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [coaLoading, setCoaLoading] = useState(false);
  const [coaFileType, setCoaFileType] = useState("");

  let storeName = localStorage.getItem("storeNameUrl");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const getInitialData = async () => {
      if (itemId !== "" && itemId !== null && itemId !== undefined) {
        if (location.state !== "add") {
          const itemRef = firestore.collection("Items").doc(itemId);
          const itemDoc = await itemRef.get();
          if (!itemDoc.exists) {
            console.log("No such document!");
          } else {
            let itemData = itemDoc.data();
            setUrl(itemData.itemImage);
            setCoaUrl(itemData.coaImage);
            setCoaFileType(itemData.coaFileType);
          }
        }
      }
    };
    getInitialData();
  }, [location]);

  const plusHandler = () => {
    !loading && document.getElementById("input").click();
  };
  const coaplusHandler = () => {
    window.scrollTo(0, 0);
    !loading && document.getElementById("coainput").click();
  };

  const imageHandler = async (e) => {
    // Firebase Item image upload
    if (e.target.files[0]) {
      const newItemKey = firestore.collection("Items").doc().id;
      const _name = storeName + "-Cannago-" + newItemKey + "img.jpg";
      setLoading(true);
      const uploadTask = storage
        .ref(`ItemImages/${_name}`)
        .put(e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          storage
            .ref("ItemImages")
            .child(_name)
            .getDownloadURL()
            .then((url) => {
              setUrl(url);
              localStorage.setItem("itemImage", url);
              setLoading(false);
            });
        }
      );
    }
  };

  const CoaHandler = async (e) => {
    //Firebase Item image upload
    if (e.target.files[0]) {
      const newItemKey = firestore.collection("Items").doc().id;
      const _name = storeName + "-Cannago-" + newItemKey + "img.jpg";
      setCoaLoading(true);
      const uploadTask = storage
        .ref(`ItemImages/${_name}`)
        .put(e.target.files[0]);
      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {},
        () => {
          storage
            .ref("ItemImages")
            .child(_name)
            .getDownloadURL()
            .then((url) => {
              setCoaUrl(url);
              setCoaFileType(e.target.files[0].type);
              localStorage.setItem("coaImage", url);
              localStorage.setItem("coaType", e.target.files[0].type);
              setCoaLoading(false);
            });
        }
      );
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        backgroundClip: "red",
      }}
    >
      <DispensaryNavbar />
      <div className="back-cont mt50">
        <GoBackButton />
      </div>
      <div className="dispensary-settings-storeadd">
        <AddItmeImage
          src={url}
          onClick={plusHandler}
          onChange={imageHandler}
          loading={loading}
          status={location.state === "add" ? "add" : "update"}
        />
        <UpdateItemSettings
          itemUrl={url}
          coaFileType={coaFileType}
          coaUrl={coaUrl}
          itemId={itemId}
          onClick={coaplusHandler}
          onChange={CoaHandler}
          loading={coaLoading}
          status={location.state === "add" ? "add" : "update"}
        />
      </div>
      <div className="large-img-cont-storeadd">
        <img src={BG} alt="" style={{ width: "100%" }} />
      </div>
      <Footer />
      {coaLoading && (
        <Spinner
          animation="border"
          variant="primary"
          style={{
            position: "absolute",
            alignSelf: "center",
            justifySelf: "center",
            width: 30,
            height: 30,
            top: 130,
          }}
        />
      )}
    </div>
  );
}

export default StoreUpdateScreen;
