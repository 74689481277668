import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

export default class Testimonials extends Component {
    render() {
        return (
            <div>
                <div className="features-title">
                    <h1>Testimonials</h1>
                </div>
                <Carousel
                    showArrows={false}
                    infiniteLoop={true}
                    showThumbs={false}
                    showStatus={false}
                    autoPlay={true}
                    interval={6100}
                >
                    <div>
                        <div className="myCarousel">
                            <p>
                                “Always a hassle finding weed whenever I visit Atlanta.
                                CannaGo solves this problem 100%. Great options,
                                ordering experience, and delivery.”<br />
                                - Layo A., Consumer
                            </p>
                            {/* <p className="layo">
                                - Layo A., Consumer
                            </p> */}
                        </div>
                    </div>

                    <div>
                        <div className="myCarousel">
                            <p>
                                "CannaGo is the best delivery app! I placed an order of Delta-8 gummies in the morning
                                and it got to my home by the afternoon. Will recommend anyone who needs a pick me
                                up to pick CannaGo.”<br />
                                - Leo M., Consumer
                            </p>
                            {/* <p>
                               - Leo M., Consumer
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <div className="myCarousel">
                            <p>
                                “Cannago has provided an excellent service!
                                Especially when it comes to educating others about
                                CBD and Delta-8 as alternatives to THC!”<br />
                                - Floretta G., Store Owner
                            </p>
                            {/* <p>
                               - Floretta G., Store Owner
                            </p> */}
                        </div>
                    </div>

                    <div>
                        <div className="myCarousel">
                            <p>
                                “By partnering with CannaGo we’ve been able to boost our sales,
                                exposure, and product offering to our customers!”<br />
                                - John W., Consumer
                            </p>
                            {/* <p>
                               - John W., Consumer
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <div className="myCarousel">
                            <p>
                                “The new last-mile delivery service brings…products
                                safely and directly to your door”<br />
                                - Hypepotamus, Press
                            </p>
                            {/* <p>
                               - Hypepotamus, Press
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <div className="myCarousel">
                            <p>
                                “The startup plans to continue expanding in metro Atlanta
                                then tackle other markets in the Southeast”<br />
                                - Business Journal, Atlanta Inno, Press
                            </p>
                            {/* <p>
                               - Business Journal, Atlanta Inno, Press
                            </p> */}
                        </div>
                    </div>
                    <div>
                        <div className="myCarousel">
                            <p>
                                “I work with several local CBD companies on their advertising.
                                Our listeners call in ALL THE TIME asking about delivery.”<br />
                                - Brittany C., Press
                            </p>
                            {/* <p>
                               - Brittany C., Press
                            </p> */}
                        </div>
                    </div>
                </Carousel>
            </div>
        );
    }
}