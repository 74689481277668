import React, {useState, useEffect} from "react";
import {useHistory} from "react-router-dom";
import firebase from "firebase/app";
import {firestore} from "../config/firebase";
import dayjs from "dayjs";
import authorizeCreditCard from "../payment/PaymentTransactions/authorize-credit-card";
import Spinner from "react-bootstrap/Spinner";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {injectStyle} from "react-toastify/dist/inject-style";
import atl_zipCode from "../constants/zipCode";
import warningIcon from "../images/warningIcon.png";
import checkoutUserImage from "../images/checkoutUserImage.png";
import checkoutCardimage from "../images/checkoutCardimage.png";
import checkoutCalendarimage from "../images/checkoutCalendarimage.png";
import checkoutZipImage from "../images/checkoutZipImage.png";
import moment from "moment";

//
let isApplied = false;
let newTemp = false;

if (typeof window !== "undefined") {
    injectStyle();
}

let today = new Date();
let day = today.getDay();
let today_Hour = today.getHours();
let today_minute = today.getMinutes();
let now_Mins = today_Hour * 60 + today_minute;

const CheckoutForm = (props) => {
    let history = useHistory();
    const submitHandler = (e) => {
        e.preventDefault();
        history.push("/orderstatus");
    };
    const [cvc, setCvc] = useState("");
    const [card, setCard] = useState("");
    const [exp, setExp] = useState("");
    const [zip, setZip] = useState("");
    const [cardZip, setCardZip] = useState("");
    const [city, setCity] = useState("");
    const [disStreet, setDisStreet] = useState("");
    const [disLineAddress, setDisLineAddress] = useState("");
    const [total, setTotal] = useState(0);
    const [items, setItems] = useState([]);
    const [taxFee, setTaxFee] = useState(0);
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [promo, setPromo] = useState("");
    const [specialIns, setSpecialIns] = useState("");
    const [opentStatus, setOpentStatus] = useState(true);
    const [promoData, setPromoData] = useState([]);
    const [storePromoData, setStorePromoData] = useState([]);
    const [loyalCodeFlag, setLoyalCodeFlag] = useState(false);
    const [loyalCodeNum, setLoyalCodeNum] = useState(0);
    const [loyalCode, setLoyalCode] = useState(0);
    const [sentInviteUserId, setSentInviteUserId] = useState("");
    const [referralValue, setReferralValue] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [checkingOut, isCheckingOut] = useState(false);
    const toastId = React.useRef(null);
    const promoButtonElement = React.useRef();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [storeId, setStoreId] = useState("");
    const [storeName, setStoreName] = useState("");
    const [ageChecked, setAgeChecked] = useState(false);
    const [idChecked, setIdChecked] = useState(false);
    const [hasReferralVal, setHasReferralVal] = useState(false);
    const [referralId, setReferralId] = useState("");

    let dispensaryEmail = "";
    let disOwnerName = "";
    let storeZipCode = "";

    const userID = props.userID;
    localStorage.setItem("userUid", userID);

    const queryParameters = new URLSearchParams(window.location.search);
    const promoCode = queryParameters.get("promoCode");

    const getReducedPrice = () => {
        let reducedTotalValue = total + total * taxFee + 4.98;
        if (total >= 50) {
            reducedTotalValue -= hasReferralVal ? discount + 20 : discount;
        }
        return reducedTotalValue;
    };

    const result = async (res) => {
        let resultCode = res.messages.resultCode;
        let responseCode = res.transactionResponse.responseCode;
        let refTransID = res.transactionResponse.refTransID === "" ? res.transactionResponse.transId : res.transactionResponse.refTransID;
        let refId = res.refId;
        let today = dayjs(new Date()).format("hh:mm A MM/DD/YYYY");
        const userDocRef = firestore.collection("User").doc(userID);
        if (resultCode === "Ok" && responseCode === "1") {
            showToast("Processing order... please wait.");
            if (isApplied) {
                if (loyalCodeFlag) {
                    await userDocRef.update({
                        loyalCode: loyalCode, loyalCodeNum: loyalCodeNum,
                    });
                } else {
                    await userDocRef.update({
                        promoFlag: false, welcomeCode: promo,
                    });

                    const currentTimestamp = firebase.firestore.FieldValue.serverTimestamp();
                    const newReferralKey = firestore.collection("ReferralValue").doc().id;

                    await firestore.collection("ReferralValue").doc(newReferralKey).set({
                        id: newReferralKey,
                        userId: sentInviteUserId,
                        referredUserId: userID,
                        referredUserEmail: userData.email,
                        referredUserFirstName: userData.firstName,
                        referredUserLastName: userData.lastName,
                        timestamp: currentTimestamp,
                    });
                }
            }

            if (newTemp && promo === "INNOBUDS10") {
                await firestore.collection("Giveaway").add({
                    uid: userID,
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    phoneNum: userData.phoneNum,
                    email: userData.email,
                });
            }

            if (newTemp && (promo === "LETSBEBUDS" || promo === "TASIABUDS15")) {
                await userDocRef.update({newFlag: false});
            } else if (newTemp && promo === "BESTBUDS10") {
                await userDocRef.update({callFlag: false});
            }

            const newOrderKey = firestore.collection("OrderItems").doc().id;
            await firestore
                .collection("CheckoutedOrder")
                .doc(newOrderKey)
                .set({
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    phoneNum: phoneNumber,
                    promo: promo,
                    checkoutedStatus: true,
                    orderId: newOrderKey,
                    orderDate: today,
                    openStatus: opentStatus,
                    dispensaryEmail: dispensaryEmail,
                    disOwnerName: disOwnerName,
                    AllPrice: getReducedPrice().toFixed(2),
                    orderItem: items,
                    salesTax: (total * taxFee).toFixed(2),
                    delivereyStreet: disStreet,
                    devliveryCity: city,
                    devliveryZip: zip,
                    delivereyLineAddress: disLineAddress,
                    userId: userID,
                });

            await firestore
                .collection("User")
                .doc(storeId)
                .update({checked: false});

            if (total >= 50 && hasReferralVal) {
                await firestore.collection("ReferralValue").doc(referralId).delete();
            }

            await firestore
                .collection("OrderItems")
                .doc(newOrderKey)
                .set({
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    phoneNum: userData.phoneNum,
                    promo: promo,
                    orderId: newOrderKey,
                    dropStreet: disStreet,
                    dropCity: city,
                    dropZip: zip,
                    dropLineAddress: disLineAddress,
                    orderItem: items,
                    placeDate: today,
                    placeStatus: true,
                    confirmDate: "",
                    confirmStatus: false,
                    deliveryDate: "",
                    deliveryStatus: false,
                    dropDate: "",
                    dropStatus: false,
                    AllPrice: getReducedPrice().toFixed(2),
                    salesTax: (total * taxFee).toFixed(2),
                    totalPrice: total.toFixed(2),
                    refTransID: refTransID,
                    refId: refId,
                    pendingStatus: "Authorized",
                    specialIns: specialIns,
                    storeId: storeId,
                    storeName: storeName,
                    userId: userID,
                });

            setLoading(false);
            isCheckingOut(false);
            window.ReactNativeWebView.postMessage("success");
        } else {
            setLoading(false);
            isCheckingOut(false);
            showToast("Oops, something went wrong. Please check card information again!");
        }
    };

    useEffect(() => {
        console.log(`setting promo code if we have any in URL params ${promoCode}`);
        if (promoCode) {
            setPromo(promoCode);
            showToast(`Please wait while we apply ${promoCode} for you.`);
        }
        // Continue with the logic now.
        const getInitialdata = async () => {
            firestore
                .collection("User")
                .doc(userID)
                .onSnapshot((documentSnapshot) => {
                    const fetchUserData = documentSnapshot.data();
                    const row = {
                        firstName: fetchUserData.fristName,
                        lastName: fetchUserData.lastName,
                        email: fetchUserData.email,
                        dropStreet: fetchUserData.dropStreet,
                        dropCity: fetchUserData.dropCity,
                        dropZip: fetchUserData.dropZip,
                        dropLineAddress: fetchUserData.dropLineAddress,
                        phoneNum: fetchUserData.phoneNum,
                        promoFlag: fetchUserData.promoFlag,
                        referralValue: fetchUserData.referralValue,
                        newFlag: fetchUserData.newFlag,
                        callFlag: fetchUserData.callFlag,
                        loyalCodeNum: fetchUserData.loyalCodeNum,
                        loyalCode: fetchUserData.loyalCode,
                        cardFirstName: fetchUserData?.cardFirstName,
                        cardLastName: fetchUserData?.cardLastName,
                        cardExp: fetchUserData?.cardExp,
                        cardNumber: fetchUserData?.cardNumber,
                        cardZip: fetchUserData?.cardZip,
                        cardCvc: fetchUserData?.cardCvc,
                        saveCard: fetchUserData?.saveCard,
                    };

                    setFirstName(row.cardFirstName);
                    setLastName(row.cardLastName);
                    setCard(row.cardNumber);
                    setExp(row.cardExp);
                    setCvc(row.cardCvc);
                    setCardZip(row.cardZip);

                    setZip(row.dropZip);
                    setDisStreet(row.dropStreet);
                    setCity(row.dropCity);
                    setUserData(row);
                    setPhoneNumber(row.phoneNum);
                    if (row.referralValue) {
                        setReferralValue(row.referralValue);
                    }
                    row.dropLineAddress && setDisLineAddress(row.dropLineAddress);
                });

            let zipCode = localStorage.getItem("zip");
            if (atl_zipCode.zip700.indexOf(zipCode) >= 0) {
                setTaxFee(0.07);
            } else if (atl_zipCode.zip775.indexOf(zipCode) >= 0) {
                setTaxFee(0.0775);
            } else if (atl_zipCode.zip800.indexOf(zipCode) >= 0) {
                setTaxFee(0.08);
            } else {
                setTaxFee(0.089);
            }
            const cartRef = firestore.collection("Carts");
            const query = cartRef.where("userId", "==", userID);
            query.onSnapshot((documentSnapshot) => {
                let cartItemdata = [];
                if (!documentSnapshot.empty) {
                    documentSnapshot.forEach((element) => {
                        const fetchUserData = element.data();
                        const row = {
                            Description: fetchUserData.Description.slice(0, 254),
                            Tag: fetchUserData.Tag,
                            id: fetchUserData.id.slice(0, 30),
                            itemImage: fetchUserData.itemImage,
                            itemNum1: fetchUserData.itemNum1,
                            itemId: fetchUserData.itemId,
                            priceValue: fetchUserData.priceValue,
                            productName: fetchUserData.productName.slice(0, 30),
                            num: fetchUserData.num,
                            variantRealArray: fetchUserData?.variantRealArray,
                            variantIndex: fetchUserData?.variantIndex,
                            storeId: fetchUserData.storeId,
                            storeName: fetchUserData.storeName,
                        };
                        cartItemdata.push(row);
                    });
                    setItems(cartItemdata);
                    setStoreId(cartItemdata[0].storeId);
                    setStoreName(cartItemdata[0].storeName);
                    timeHandler(cartItemdata[0].storeId);
                    let totalAmount = 0;
                    cartItemdata.map((item) => {
                        totalAmount = totalAmount + item.priceValue * item.num;
                    });
                    setTotal(parseFloat(totalAmount));
                } else {
                    setItems([]);
                }
            });
            const referralDocRef = firestore.collection("ReferralValue");
            const referralDocSnapshot = await referralDocRef
                .where("userId", "==", userID)
                .orderBy("timestamp")
                .limit(1)
                .get();
            if (!referralDocSnapshot.empty) {
                const referralVal = referralDocSnapshot.docs[0].data();
                setHasReferralVal(true);
                setReferralId(referralVal.id);
            }

            const promoDocRef = firestore.collection("PromoCodes");
            const promoDocSnapshot = await promoDocRef.get();
            const promoCodeData = promoDocSnapshot.docs.map((doc) => doc.data());
            setPromoData(promoCodeData);

            const storePromoDocRef = firestore.collection("StorePromoCodes");
            const storePromoDocSnapshot = await storePromoDocRef.get();
            const storePromoCodeData = storePromoDocSnapshot.docs.map((doc) => doc.data());
            console.log("storePromoCodeData", storePromoCodeData);
            setStorePromoData(storePromoCodeData);
        };
        getInitialdata().finally(() => {
            //Apply Promo Code if Available in URL

            if (promoCode && parseFloat(total) > 0) {
                console.log(promoCode);
                console.log(total);
                document.getElementById("promoButtonElement").click();
            }
        });
    }, [total]);

    const timeHandler = (storeId) => {
        firestore
            .collection("User")
            .doc(storeId)
            .onSnapshot((documentSnapshot) => {
                const fetchStoreData = documentSnapshot.data();
                let openStatus = fetchStoreData.storeHours[day].openStatus;
                let online = fetchStoreData.storeHours[day].online;

                if (online === true) {
                    if (openStatus) {
                        localStorage.setItem("openStatus", "true");
                        setOpentStatus(true);
                    } else {
                        localStorage.setItem("openStatus", "false");
                        setOpentStatus(false);
                    }
                }
            });
    };

    const expHandler = (e) => {
        let value = e.nativeEvent.data;
        if (value >= 0 && value <= 9) {
            if (e.target.value.length === 2 && !e.target.value.includes("/")) {
                e.target.value += "/";
            } else if (e.target.value.length === 1 && e.target.value.includes("/")) {
                e.target.value = "";
            }
            setExp(e.target.value);
        }
    };
    const cvcHandler = (e) => {
        const {value, maxLength} = e.target;
        const message = value.slice(0, maxLength);
        setCvc(message);
    };

    const cardInput = (e) => {
        let value = e.nativeEvent.data;
        if ((value >= 0 && value <= 9) || value === " ") {
            setCard(e.target.value
                .replace(/\s/g, "")
                .replace(/(\d{4})/g, "$1 ")
                .trim());
        }
    };

    const checkOut = async () => {
        setLoading(true);
        isCheckingOut(true);
        window.scrollTo(0, 0);

        const storeDocRef = firestore.collection("User").doc(storeId);
        const storeDocSnapshot = await storeDocRef.get();
        dispensaryEmail = storeDocSnapshot.data().email;
        disOwnerName = storeDocSnapshot.data().fristName;
        storeZipCode = storeDocSnapshot.data().zipCode;

        if (!card || !cvc || !exp || !disStreet || !city || !zip) {
            showToast("Please fill all fields.");
            setLoading(false);
            isCheckingOut(false);
        } else {
            const uid = userID.substr(0, 10);
            const today = dayjs(new Date()).format("hh:mmA");
            const refId = uid + today;
            const cardNum = card.replace(/\s/g, "").trim();
            const expDate = exp.replace(/\//g, "").trim();
            const data = {
                productAmount: getReducedPrice().toFixed(2),
                cardNum: cardNum,
                expDate: expDate,
                cvc: cvc,
                refId: refId,
                firstName: firstName,
                lastName: lastName,
                delivereyStreet: disStreet,
                zipCode: cardZip,
                devliveryCity: city,
                devliveryZip: zip,
                storeZipCode: storeZipCode,
                items: items,
                salesTax: (total * taxFee).toFixed(2),
            };
            authorizeCreditCard.authorizeCreditCard(result, data);
            //result();
        }
    };

    // Toast function: makes sure multiple toasts don't show at the same time
    const showToast = (val) => {
        if (!toast.isActive(toastId.current)) {
            toastId.current = toast(val);
        }
    };

    // Function to apply promo codes
    const promoApply = async () => {
        let appliedStorePromo = false;
        const currentDate = moment(); // Assuming this is how you get the current date
        // Check if promo code matches predefined store promos
        const predefinedPromos = {
            LETSBEBUDS: {discount: 5, eligibilityCheck: userData.newFlag},
            BESTBUDS10: {
                discount: 10, eligibilityCheck: userData.callFlag !== false,
            },
            INNOBUDS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            SHAMANBUDS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            YOURCBD15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            "2381BUDS15": {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            FANBUDS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            FRIENDLYFRIDAY20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2), eligibilityCheck: true,
            },
            BUD5OFF: {discount: 5, eligibilityCheck: true},
            SURVEY10: {discount: 10, eligibilityCheck: true},
            PLANT10: {
                discount: parseFloat((total + total * taxFee) * 0.1).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2022-12-04", "2022-12-07", undefined, "[]"),
            },
            DECEMBER12: {
                discount: parseFloat((total + total * taxFee) * 0.12).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2022-12-06", "2023-01-01", undefined, "[]"),
            },
            HOLIDAZE5: {
                discount: 5,
                eligibilityCheck: moment(currentDate).isBetween("2022-12-10", "2022-12-13", undefined, "[]"),
            },
            AVALANCHE10: {
                discount: 10,
                eligibilityCheck: moment(currentDate).isBetween("2022-12-13", "2022-12-16", undefined, "[]"),
            },
            XMAS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2022-12-17", "2022-12-25", undefined, "[]"),
            },
            STOCKSTUFF10: {
                discount: 10, eligibilityCheck: moment(currentDate).isSame("2022-12-20"),
            },
            WINTERSOLE5: {
                discount: 5, eligibilityCheck: moment(currentDate).isSame("2022-12-22"),
            },
            SANTA20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2),
                eligibilityCheck: moment(currentDate).isSame("2022-12-24"),
            },
            NEWYEARS10: {
                discount: 10, eligibilityCheck: moment(currentDate).isSame("2022-12-24"),
            }, // Add more promo codes as needed

            APRIL35: {
                discount: parseFloat((total + total * taxFee) * 0.35).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-01", "2024-04-30", undefined, "[]"),
            },

            BUDZ50: {
                discount: parseFloat((total + total * taxFee) * 0.5).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-01", "2024-04-30", undefined, "[]"),
            },

            FOOLS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-01", "2024-04-03", undefined, "[]"),
            },

            FUN10: {
                discount: 10,
                eligibilityCheck: moment(currentDate).isBetween("2024-04-05", "2024-04-06", undefined, "[]"),
            },
            START20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-09", "2024-04-10", undefined, "[]"),
            },
            BLOOM5: {
                discount: 5,
                eligibilityCheck: moment(currentDate).isBetween("2024-04-11", "2024-04-12", undefined, "[]"),
            },

            RESET25: {
                discount: parseFloat((total + total * taxFee) * 0.25).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-14", "2024-04-15", undefined, "[]"),
            },

            GO10: {
                discount: 5,
                eligibilityCheck: moment(currentDate).isBetween("2024-04-17", "2024-04-18", undefined, "[]"),
            },
            INNOBUDS10: {discount: 10, eligibilityCheck: true},
            SHAMANBUDS10: {discount: 10, eligibilityCheck: true},
            YOURCBD10: {discount: 10, eligibilityCheck: true},
            "2381BUDS10": {discount: 10, eligibilityCheck: true},
            CBD10: {discount: 10, eligibilityCheck: true},
            WJOINT10: {discount: 10, eligibilityCheck: true},
            UPLIFTED: {discount: 10, eligibilityCheck: true},
            COASTAL10: {discount: 10, eligibilityCheck: true},
            MALAWI10: {discount: 10, eligibilityCheck: true},
            VEGA10: {discount: 10, eligibilityCheck: true},
            CBDCITY10: {discount: 10, eligibilityCheck: true},
            BUDBAG20: {discount: 20, eligibilityCheck: true},
            YOFURG10: {discount: 10, eligibilityCheck: true},
            URBUD10: {discount: 10, eligibilityCheck: true},
            BUDS10: {discount: 10, eligibilityCheck: true},
            BUDDY10: {discount: 10, eligibilityCheck: true},
            GIFT10: {discount: 10, eligibilityCheck: true},
            FAVE10: {discount: 10, eligibilityCheck: true}, //10% off ones

            //15% off permanent ones
            FURGBUD15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            WFBW15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            TROYMAN15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            ONEFEST15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            DREAM15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            UTD15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            FALCON15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },
            HAWKS15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2), eligibilityCheck: true,
            },

            //20% off ones
            HOUSE20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2), eligibilityCheck: true,
            },
            TECH20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2), eligibilityCheck: true,
            },
            GOHAPP20: {
                discount: parseFloat((total + total * taxFee) * 0.2).toFixed(2), eligibilityCheck: true,
            },

            //25% ones
            LEAF25: {
                discount: parseFloat((total + total * taxFee) * 0.25).toFixed(2), eligibilityCheck: true,
            },

            NATALIE10: {discount: 10, eligibilityCheck: userData.newFlag},
            MORGAN10: {discount: 10, eligibilityCheck: userData.newFlag},
            BRETT10: {discount: 10, eligibilityCheck: userData.newFlag},
            EMELIA10: {discount: 10, eligibilityCheck: userData.newFlag},
            TASHIA10: {discount: 10, eligibilityCheck: userData.newFlag},

            FOUR20: {
                discount: 20,
                eligibilityCheck: moment(currentDate).isBetween("2024-04-20", "2024-04-21", undefined, "[]"),
            },
            EARTH30: {
                discount: parseFloat((total + total * taxFee) * 0.30).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-22", "2024-04-23", undefined, "[]"),
            },
            GREEN15: {
                discount: parseFloat((total + total * taxFee) * 0.15).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-25", "2024-04-26", undefined, "[]"),
            },

            FLASH40: {
                discount: parseFloat((total + total * taxFee) * 0.40).toFixed(2),
                eligibilityCheck: moment(currentDate).isBetween("2024-04-29", "2024-05-01", undefined, "[]"),
            },

        };


        if (predefinedPromos.hasOwnProperty(promo)) {
            // alert("IN PROMO PREDEFINED TABLE"+promo);
            const promoInfo = predefinedPromos[promo];
            // console.log(promoInfo)
            appliedStorePromo = true;
            if (!newTemp && promoInfo.eligibilityCheck) {
                setDiscount(promoInfo.discount);
                newTemp = true;
                showToast("Promo code will be applied at checkout");
            } else {
                showToast("Sorry you are not eligible for this discount");
            }
        } else {
            // Check if promo code matches store promo data
            for (const element of storePromoData) {
                if (element.code === promo) {
                    appliedStorePromo = true;
                    if (!newTemp) {
                        if (element.dollarAmt) {
                            setDiscount(element.dollarAmt);
                        } else {
                            setDiscount(parseFloat((total + total * taxFee) * element.percentage).toFixed(2));
                        }
                        newTemp = true;
                        showToast("Promo code will be applied at checkout");
                    } else {
                        showToast("Sorry you are not eligible for this discount");
                    }
                    break;
                }
            }
        }

        if (!appliedStorePromo) {
            newTemp = false;
            if (total >= 50) {
                if (userData.promoFlag === true || promoCode) {
                    for (const promoItem of promoData) {
                        if (promoItem.promoCode === promo && promoItem.email !== userData.email) {
                            if (!isApplied) {
                                isApplied = true;
                                newTemp = true;
                                if (promoItem.codetype === "loyaltyCode") {
                                    if (items[0].storeId === promoItem.userId) {
                                        setLoyalCode(promoItem.promoCode);
                                        setLoyalCodeFlag(true);
                                        showToast(`${promoItem.promoCode} code has been applied`);
                                        if (userData.loyalCode === promoItem.promoCode) {
                                            if (userData.loyalCodeNum + 1 === 3) {
                                                setDiscount(10);
                                                setLoyalCodeNum(0);
                                            } else {
                                                setLoyalCodeNum(userData.loyalCodeNum + 1);
                                            }
                                        } else {
                                            setLoyalCodeNum(1);
                                        }
                                    } else {
                                        setLoyalCodeFlag(false);
                                    }
                                } else {
                                    setSentInviteUserId(promoItem.userId);
                                    await firestore
                                        .collection("User")
                                        .doc(promoItem.userId)
                                        .get();
                                    setLoyalCodeFlag(false);
                                    setDiscount(20);
                                    showToast("The referral code has been applied successfully.");
                                }
                            }
                            break;
                        }
                    }
                } else {
                    showToast("You already used the referral code.");
                }
            } else {
                showToast("Your order must be $50 or more for the promo code to apply.");
            }
        }
    };

    const changePromoValue = (e) => {
        setPromo(e.target.value);
        isApplied = false;
        newTemp = false;
        setDiscount(0);
    };
    return (<div className="checkout-form-cont">
        <div className="checkout-form">
            <p className="webView-checkout-form-text margin-top-2">
                Card Information
            </p>
            <div className="webView-checkout-form-cardd">
                <div className="webView-checkout-form-card">
                    <img
                        src={checkoutUserImage}
                        style={{width: 30, height: 30, marginLeft: 15}}
                        alt="checkoutUserImage"
                    />
                    <input
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        placeholder="First Name"
                        type="text"
                    />
                </div>
                <div className="webView-checkout-form-card">
                    <img
                        src={checkoutUserImage}
                        style={{width: 30, height: 30, marginLeft: 15}}
                        alt="checkoutUserImage"
                    />
                    <input
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                    />
                </div>
            </div>
            <div className="webView-checkout-form-pr">
                <div className="webView-checkout-form-promo">
                    <img
                        src={checkoutCardimage}
                        style={{width: 30, height: 25, marginLeft: 15}}
                        alt="checkoutCardimage"
                    />
                    <input
                        onChange={(e) => cardInput(e)}
                        maxLength={19}
                        value={card}
                        placeholder="Card number"
                    />
                </div>
            </div>
            <div className="webView-checkout-form-address">
                <div className="webView-checkout-form-address-item">
                    <img
                        src={checkoutCalendarimage}
                        style={{width: 20, height: 20, marginLeft: 10}}
                        alt="checkoutCalendarimage"
                    />
                    <input
                        value={exp}
                        onChange={(e) => expHandler(e)}
                        placeholder="MM/YY"
                        maxLength={5}
                    />
                </div>
                <div className="webView-checkout-form-address-item">
                    <img
                        src={checkoutZipImage}
                        style={{width: 20, height: 20, marginLeft: 10}}
                        alt="checkoutZipImage"
                    />
                    <input
                        placeholder="CVV"
                        value={cvc}
                        onChange={cvcHandler}
                        type="number"
                        maxLength="4"
                    />
                </div>
                <div className="webView-checkout-form-address-item">
                    <img
                        src={checkoutZipImage}
                        style={{width: 20, height: 20, marginLeft: 10}}
                        alt="checkoutZipImage"
                    />
                    <input
                        value={cardZip}
                        onChange={(e) => setCardZip(e.target.value)}
                        placeholder="Zip code"
                        maxLength={5}
                        type="number"
                    />
                </div>
            </div>
            <p className="webView-checkout-form-text">Confirm Delivery Address</p>
            <div className="webView-checkout-form-pr">
                <div className="webView-checkout-form-promo">
                    <p>{disStreet}</p>
                </div>
            </div>
            <div className="webView-checkout-form-pr">
                <div className="webView-checkout-form-promo">
                    <p>
                        {disLineAddress ? disLineAddress : "Apt, Building, Suite (Optional)"}
                    </p>
                </div>
            </div>
            <div className="webView-checkout-form-address">
                <p>{city}</p>
                <p>GA</p>
                <p>{zip}</p>
            </div>
            <p className="webView-checkout-form-text">Special Instructions</p>
            <div className="checkout-form-specialr">
                <div className="checkout-form-special-request">
            <textarea
                placeholder="Unit Number, Gate Code, Building (Optional)"
                value={specialIns}
                onChange={(e) => setSpecialIns(e.target.value)}
            />
                </div>
            </div>
            {/*Promo Code here*/}
            <p className="webView-checkout-form-text">Promo Code</p>
            <div className="webView-checkout-form-pr">
                <div className="webView-checkout-form-promo">
                    <input
                        placeholder="Referral/Promo Code"
                        value={promo}
                        onChange={(e) => {
                            changePromoValue(e);
                        }}
                    />
                    <button type="button" id="promoButtonElement" onClick={promoApply}>
                        {promoCode ? `Applied.` : "Apply"}
                    </button>
                </div>
            </div>

            <div className="checkout-form-detailed">
                <div className="webView-checkout-form-details margin-top-1">
                    <p>Items Price</p>
                    <p>${total.toFixed(2)}</p>
                </div>
                <div className="webView-checkout-form-details">
                    <p>Service Fee</p>
                    <p>$2.99</p>
                </div>
                <div className="webView-checkout-form-details">
                    <p>Delivery Fee</p>
                    <p>$1.99</p>
                </div>
                <div className="webView-checkout-form-details">
                    <p>State and Sales Tax</p>
                    <p>${(total * taxFee).toFixed(2)}</p>
                </div>
                {discount > 0 && newTemp === true ? (<div
                    className="webView-checkout-form-details"
                    style={{marginTop: 0}}
                >
                    <p>
                        Discount{" "}
                        <span style={{color: "red"}}>
                  {promoCode ? `(${promoCode})` : ""}{" "}
                </span>
                    </p>
                    <p style={{color: "red"}}>
                        -${parseFloat(discount).toFixed(2)}
                    </p>
                </div>) : ("")}
            </div>

            <div className="webView-checkout-form-details" style={{marginTop: 0}}>
                <p style={{fontSize: 20, fontWeight: "600"}}>Total</p>
                <p style={{fontSize: 20, fontWeight: "600"}}>
                    ${getReducedPrice().toFixed(2)}
                </p>
            </div>
            {opentStatus === false ? (<div cl assName="timeAlert" style={{maginTop: 0}}>
                <img src={warningIcon} alt="" style={{width: 18, height: 18}}/>
                <p style={{textAlign: "center", padding: 0, fontSize: 12}}>
                    You’re shopping after hours. Please allow a two day window for
                    your product(s) to be delivered.
                </p>
            </div>) : ("")}
            {idChecked && ageChecked && checkingOut === false ? (<button
                className="checkout-form-submit"
                style={{width: "90%", borderRadius: 5}}
                type="submit"
                onClick={checkOut}
            >
                Check Out
            </button>) : (<button
                className="checkout-form-submit"
                style={{width: "90%", borderRadius: 5}}
                type="submit"
                disabled={checkingOut}
            >
                Check Out
            </button>)}
            {/* {showMessage && <p>{showMessage}</p>} */}
            <div className="terms-conditions" style={{marginBottom: 10}}>
                <input
                    type="checkbox"
                    defaultChecked={ageChecked}
                    onChange={(e) => {
                        setAgeChecked(e.target.value);
                    }}
                    style={{width: 15}}
                    id="ageCheck"
                    name="ageCheck"
                    required
                />
                <label htmlFor="ageCheck" id="agreeLabel" style={{fontSize: 13}}>
                    {" "}
                    By checking this I certify I am 21 years or older.{" "}
                </label>
            </div>
            <div className="terms-conditions" style={{marginBottom: 20}}>
                <input
                    type="checkbox"
                    defaultChecked={idChecked}
                    onChange={(e) => {
                        setIdChecked(e.target.value);
                    }}
                    style={{width: 23}}
                    id="usIdCheck"
                    name="usIdCheck"
                    required
                />
                <label htmlFor="usIdCheck" id="agreeLabel" style={{fontSize: 13}}>
                    {" "}
                    By checking this I am willing to show a valid US ID when requested
                    upon delivery.{" "}
                </label>
            </div>
        </div>
        <ToastContainer/>
        {loading && (<Spinner
            animation="border"
            variant="primary"
            className="cart-loading-border"
        />)}
    </div>);
};
export default CheckoutForm;
