import React from 'react'
import { useHistory, Link } from "react-router-dom";

import cannabisImg from "../images/cannabis-img.png";
import homePage from "../images/homepage.png";
import itemsPage from "../images/itemspage.png";
import selectedItem from "../images/selecteditem.png";


const Header = () => {
    let history = useHistory();

    const gotoSignUp = () => {
        history.push('/login')
    }

    return (
        <header className="header">

            {/* <img src={cannabisImg} alt="cannabis-img" className="header-img-1" />
            <img src={cannabisImg} alt="cannabis-img" className="header-img-2" /> */}
            <div className="landing-page-container">
                <div className="header-container">
                    <h1 className="h1-heading">Delta 8 and CBD</h1>
                    <h1 className="h1-heading" style={{ marginTop: 0 }}>Delivered Same Day</h1>
                    <h1 className="h1-heading" style={{ marginTop: 0 }}>Legally to your door </h1>
                    <h1 className="h1-heading-sub" style={{ marginTop: 0, color: 'black', fontSize: 20 }}>Serving Atlanta and Eastern Alabama</h1>
                    <h1 className="h1-heading" style={{ marginTop: 0, color: 'rgba(97, 210, 115, 1)' }}>Shop 100's of products now</h1>
                    <div className="button-container">
                        <a href="https://apps.apple.com/us/app/cannago-cbd-hemp-delivery/id1555758423">
                            <button className="btn-download">Download on iOS</button>
                        </a>
                        {/*<div className="divider"/>
                        <a href={CannaGo} download="cannago.apk">
                            <button className="btn-download" >Download Android</button>
                        </a>*/}
                    </div>
                    {/* <p className="p-text">
                    Delivery of non-medicated skin care products, cosmetics, and essential oils that include CBD, and vaping and smoking products including CBD, any CBD in the goods being featured or provided in the aforementioned services being solely derived from hemp with a delta-9 tetrahydrocannabinol (THC) concentration of not more than 0.3 percent on a dry weight basis.
                    </p> */}
                </div>
                <img src={homePage} className="page-img" />
                <img src={itemsPage} className="page-img" />
                <img src={selectedItem} className="page-img" />

            </div>
            <div className="order-message">
                <p>Make orders before 5pm for same day<br />
                    CannaGo delivers THC-free CBD consumables, non-medicated skin care products, cosmetics, essential oils, vaping and smoking products.</p>
            </div>
        </header>
    )
}

export default Header
